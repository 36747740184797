import { DatePicker, Input, Select, Space, TimePicker, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from 'dayjs';
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { TfiReload } from "react-icons/tfi";
import { store } from "../Helper/Store";
import "./appointmentpage.css";
import PostApiCall from ".././API/POSTAPI";
import Form from "react-bootstrap/Form";
import moment from "moment";
import CalendarTime from "./CalendarTime";
import { Modal } from "react-bootstrap";
import GetApiCall from "../API/GETAPI";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { MdCurrencyRupee } from "react-icons/md";

export default function Appointmentform({ serviceName, showForm, closeModal }) {
  useEffect(() => {
    generateRandomCaptcha();
    // GetAllBookedSlot();
  }, []);
  // const GetAllBookedSlot = () => {
  //   PostApiCall.postRequest(
  //     {
  //       catCount: "*",
  //       whereClause: "",
  //     },
  //     "GetAllAppointments"
  //   ).then((results) =>
  //     results.json().then((obj) => {
  //       if (results.status === 200 || results.status === 201) {
  //         setBookedSlots(obj.data);
  //       } else {
  //         Notiflix.Notify.failure("Something went wrong!");
  //       }
  //     })
  //   );
  // };

  // const [bookedSlots, setBookedSlots] = useState([]);

  const [captcha, setCaptcha] = useState("");
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [mobile, setMobile] = useState("");
  const [dialcode, setDialcode] = useState("");
  const [email, setEmail] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthTime, setBirthTime] = useState("");
  const [showDOB, setShowDOB] = useState(false);
  const [showTOB, setShowTOB] = useState(false);
  const [showPOB, setShowPOB] = useState(false);

  const [message, setMessage] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [currentStatus, setCurrentStatus] = useState("New");
  const [serviceData, setServiceData] = useState([]);
  const [timeInterval, setTimeInterval] = useState("")
  const [vastuArea, setVastuArea] = useState("")
  // states for service price
  const [halfHourprice, setHalfHourPrice] = useState(null);
  const [oneHourprice, setOneHourPrice] = useState(null);
  const [bookingprice, setBookingPrice] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);


  // image upload
  const [fileList, setFileList] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [urlData, setUrlData] = useState([]);
  useEffect(() => {
    GetServiceData();
  }, []);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: "",
      },
      "Services"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setServiceData(
            obj1.data.filter((data) => data.fld_status === "Active")
          );
        }
      })
    );
  };

  // Calendar

  const [showSlot, setShowSlot] = useState(false);

  function disabledDate(current) {
    if (current && current.isBefore(moment(), "day")) {
      return true;
    }
  }

  const handleChangeDate = (value) => {
    setSelectedDate(value?.format("DD/MM/YYYY"));
    setShowSlot(true);
  };

  // dialcode phone number
  const handleCountryChange = (value, country) => {
    setMobile(country.inputValue)
    setDialcode("+" + country.country.dialCode)
  };

  // handleChange , when user select service and clear other details if service is changed

  const handleChange = (value, servicePrices) => {
    setHalfHourPrice(servicePrices?.fld_price ? servicePrices?.fld_price : null)
    setOneHourPrice(servicePrices?.fld_price_1_hr ? servicePrices?.fld_price_1_hr : null)
    setBookingPrice(servicePrices?.fld_booking_amount ? (servicePrices?.fld_booking_amount, setSelectedAmount(servicePrices?.fld_booking_amount)) : null)
    setSelectedService(value);
    clearFormData("serviceChange")
    switch (value) {
      case "Astrology":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Vastu Shastra":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Handwriting and Signature Analysis":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Numerology":
        setShowDOB(true)
        setShowPOB(false);
        setShowTOB(false);
        break;
      case "Palmistry":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Tarot":
        setShowDOB(false)
        setShowPOB(false);
        setShowTOB(false);
        break;
      case "Reiki Healing":
        setShowDOB(false)
        setShowPOB(false);
        setShowTOB(false);
        break;
      default:
        setShowDOB(false)
        setShowPOB(false);
        setShowTOB(false);
    }
  };

  const handleRegexValidation = (email, phone) => {
    let result;
    if (email) {
      result = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    } else {
      result = /^\d{10}$/.test(phone);
    }
    return result;
  };
  const generateRandomCaptcha = () => {
    const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
    setCaptcha(newCaptcha);
  };
  function isCaptchaValid(enteredCaptcha) {
    return captcha == enteredCaptcha;
  }

  // form submition validation
  function handleValidateForm() {
    if (selectedService === "") {
      Notiflix.Notify.failure("Please select a service first.", { zindex: 99999 });
      return;
    }
    if (name === "") {
      Notiflix.Notify.failure("Please enter your name.", { zindex: 99999 });
      return;
    }
    if (gender === "") {
      Notiflix.Notify.failure("Please select your gender", { zindex: 99999 });
      return;
    }
    if (maritalStatus === "") {
      Notiflix.Notify.failure("Please select your martial status", { zindex: 99999 });
      return;
    }
    if (showDOB === true && birthDate === "") {
      Notiflix.Notify.failure("Please select your birth date", { zindex: 99999 });
      return;
    }
    if (showTOB === true && birthTime === "") {
      Notiflix.Notify.failure("Please select your birth time", { zindex: 99999 });
      return;
    }
    if (showPOB === true && birthPlace === "") {
      Notiflix.Notify.failure("Please enter your birth place", { zindex: 99999 });
      return;
    }
    if (selectedService === "Vastu Shastra" && vastuArea === "") {
      Notiflix.Notify.failure("Vastu area is required", { zindex: 99999 });
      return;
    }
    if (mobile === "" || mobile.replace(/\s+/g, '') === dialcode.replace(/\s+/g, '')) {
      Notiflix.Notify.failure("Please enter your phone mobile number", { zindex: 99999 });
      return;
    }
    if (email == "") {
      Notiflix.Notify.failure("Please enter your email address", { zindex: 99999 });
      return;
    } else if (!handleRegexValidation(email, "")) {
      Notiflix.Notify.failure("Invalid email address", { zindex: 99999 });
      return;
    }
    if (selectedService === "Palmistry" && urlData.length !== 2) {
      Notiflix.Notify.failure("Please upload both the front and rear hand images", { zindex: 99999 });
      return;
    }
    if (selectedService === "Handwriting and Signature Analysis" && urlData.length !== 1) {
      Notiflix.Notify.failure(" Please upload handwritting or signature image", { zindex: 99999 });
      return;
    }
    if (selectedDate === "") {
      Notiflix.Notify.failure("Please select appointment date", { zindex: 99999 });
      return;
    }
    if (bookingprice === null && timeInterval === "") {
      Notiflix.Notify.failure("Please select appointment time duration", { zindex: 99999 });
      return;
    }
    if (bookingprice === null && selectedTime === "") {
      Notiflix.Notify.failure("Please select appointment time", { zindex: 99999 });
      return;
    }
    // if (message === "") {
    //   Notiflix.Notify.failure("Message is required", { zindex: 99999 });
    //   return;
    // }
    if (!isCaptchaValid(enteredCaptcha)) {
      Notiflix.Notify.failure("Invalid Captcha", { zindex: 99999 });
      return;
    }
    onSubmit();
    // handlePayment(selectedAmount)
  }


  function onSubmit() {
    Notiflix.Loading.dots("Please wait...", { zindex: 99999 });
    PostApiCall.postRequest(
      {
        appointment_id: null,
        name: name,
        gender: gender,
        maritalStatus: maritalStatus,
        tob: birthTime !== "" ? birthTime : null,
        dob: birthDate !== "" ? birthDate : null,
        birthPlace: birthPlace,
        mobile: mobile,
        email: email,
        area: vastuArea !== "" ? vastuArea : null,
        time: selectedTime,
        slotduration: timeInterval,
        date: selectedDate,
        service: selectedService,
        image1: urlData.length > 0 ? selectedService === "Handwriting and Signature Analysis" || selectedService === "Palmistry" ? urlData[0].imageUrl : null : null,
        image2: urlData.length == 2 && selectedService === "Palmistry" ? urlData[1].imageUrl : null,
        message: message,
        status: currentStatus,
      },
      "BookAppointment"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          clearFormData("submit");
          Notiflix.Loading.remove();
          closeModal();
          Notiflix.Notify.success("Form Submitted Successfully.", {
            zindex: 99999,
          });
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong!", { zindex: 99999 });
        }
      })
    );
  }
  // pay to razorpay
  const handlePayment = async (amount) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    // e.preventDefault();
    try {
      const response = await axios.post(
        // "http://localhost:3035/blessings-api/payment",
        "https://api.bechofy.in/blessings-api/payment",
        { amount }
      );
      const { order } = response.data;
      var options = {
        key: process.env.REACT_PAYMENT_KEY || "rzp_live_PDnM4qUQpUe7rE", // Enter the Key ID generated from the Dashboard
        amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: process.env.REACT_NAME || "Blessings Astrology", //your business name
        description: "Blessings Astrology",
        image: "",
        order_id: order.id, // Pass the `id` obtained in the response of Step 1
        handler: (response) => {
          const body = {
            ...response,
          };

          fetch(
            // "http://localhost:3035/blessings-api/RazorPayPaymentStatus",
            "https://api.bechofy.in/blessings-api/RazorPayPaymentStatus",
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((validateRes) => validateRes.json())
            .then((jsonRes) => {
              axios
                .post(
                  // "http://localhost:3035/blessings-api/RazorPayResponse",
                  "https://api.bechofy.in/blessings-api/RazorPayResponse",
                  {
                    cartid: null,
                    customerid:
                      localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_customerid,
                    amount: selectedAmount,
                    productinfo: "",
                    firstname:
                      name,
                    email:
                      email,
                    mihpayid: jsonRes.status.id,
                    requestid: jsonRes.status.order_id,
                    txnamount: jsonRes.status.amount,
                    errorcode: jsonRes.status.error_code,
                    paymentsource: jsonRes.status.method,
                    errormsg: jsonRes.status.error_description,
                    shippingaddress: null,
                    billingaddress: null,
                    status: jsonRes.status.status,
                  }
                )
                .then((obj) => {
                  if (obj.data.message === true) {
                    // Redirect to payment success page
                    // handleSendNotification(jsonRes.status.order_id);
                    // history.push(`/paymentsuccess/${paymentTrxNum}`);
                    // window.location.href = `/paymentsuccess/${paymentTrxNum}`;
                  } else {
                    // Redirect to payment failure page
                    // window.location.href = `/paymentfail/${paymentTrxNum}`;
                  }
                });
              // window.location.href = "/paymentsuccess"
            })
            .catch((error) => {
              console.error("Error fetching payment status:", error);
            });
        },
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
          name:
            name, //your customer's name
          email:
            email,
          contact: mobile, //Provide the customer's phone number for better conversion rates
        },
        // notes: {
        //   cartid: null,
        //   itemsdetaiils: JSON.stringify(cartItemsDetail),
        //   paymentTrxNum: paymentTrxNum,
        // },
        theme: {
          color: "#ad9551",
        },
      };

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        // Razorpay script is loaded, you can now use window.Razorpay
      };
      document.head.appendChild(script);
      const rzp = new window.Razorpay(options);
      // setShowCanvas(true)
      rzp.open();
    } catch (error) {
      console.error(error);
    }
  };
  // clear form details after submit
  const clearFormData = (action) => {
    setName("");
    setGender("");
    setVastuArea("")
    setMaritalStatus("");
    setBirthPlace("");
    setMobile("");
    setTimeInterval("")
    setEmail("");
    setSelectedTime("");
    setMessage("");
    setUrlData([])
    setFileList([])
    if (action === "submit") {
      setSelectedService("")
      setBirthTime("")
      setBirthDate("")
      setSelectedDate("");
    }
  }

  // upload button and function for uploading images 

  const handleChangeImage = async ({ file, fileList }) => {
    const updatedFileList = [...fileList];
    const index = updatedFileList.findIndex((item) => item.uid === file.uid);
    if (index !== -1) {
      updatedFileList[index] = { ...file, status: "done" };
      setFileList(updatedFileList);
    }
    if (file.status === "done") {
      updatedFileList[index] = { ...file, status: "done" };
      setFileList(updatedFileList);
    } else if (file.status === "error") {
      updatedFileList[index] = { ...file, status: "error" };
      setFileList(updatedFileList);
    } else if (file.status === "removed") {
      setFileList(updatedFileList.filter((item) => item.uid !== file.uid));
      setUrlData(urlData.filter((item) => item.uid !== file.uid))
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const onaction = async (dat) => {
    const formData = new FormData();
    formData.append("filename", dat.file.name.replace(/ /g, "-"));
    formData.append("foldername", "ProductReviewImages");
    formData.append("file", dat.file);
    await axios
      .post("https://api.bechofy.in/AddImage", formData, {
        headers: {
          Schema: "Blessings",
        },
      })
      .then((res) => {
        setUrlData([...urlData, { uid: dat.file.uid, imageUrl: res.data.image }]);
      })
      .catch((err) => {
        if (err.response) {
          console.log("Error Response:", err.response.data);
          console.log("Error Status:", err.response.status);
          console.log("Error Headers:", err.response.headers);
        } else if (err.request) {
          console.log("Error Request:", err.request);
        } else {
          console.log("Error Message:", err.message);
        }
        console.log("Error Config:", err.config);
      });
  };

  return (
    <>
      <Modal
        show={showForm}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        size="xl"
        className="appointment-form-modal"
      >
        <Modal.Header closeButton className="pb-0"></Modal.Header>
        <Modal.Body className="p-lg-0">

          <section className="spacing">
            <h2 className=" mt-3 text-center"> Book An Appointment</h2>
            <p className="text-center">
              Schedule Your Personalized Consultation with Ease{" "}
            </p>
            <div className="container-fluid mb-3">
              <div className="row contact-form mt-3 select-service-box">
                <div className="col-lg-12 mb-4 select-service-option">
                  <div className="mb-2">Kindly select a service before entering additional information. <span className="text-danger">*</span></div>
                  {/* <label for="service">Preferred Service <span className="text-danger">*</span></label> */}
                  <Form.Select
                    id="service"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setSelectedAmount(null)
                      const selectedServiceData = serviceData.find((serviceinfo) => serviceinfo.fld_service_name === e.target.value)
                      const servicePrices = JSON.parse(selectedServiceData?.price_info);
                      handleChange(e.target.value, servicePrices)
                    }}
                    defaultValue={selectedService}
                  >
                    <option value disabled selected>
                      Select Service
                    </option>
                    {serviceData.map((items, index) => (
                      <option value={items.fld_service_name} key={index}>
                        {items.fld_service_name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="name">Name <span className="text-danger">*</span></label>
                  <Input
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="gender">Gender <span className="text-danger">*</span></label>
                  <Form.Select
                    id="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    defaultValue={gender}
                    disabled={selectedService !== "" ? false : true}
                  >
                    <option value="" disabled >
                      Select Gender
                    </option>
                    <option value="Male">
                      Male
                    </option>
                    <option value="Female">
                      Female
                    </option>
                    <option value="Other">
                      Other
                    </option>

                  </Form.Select>
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="maritalStatus">Marital Status <span className="text-danger">*</span></label>
                  <Form.Select
                    id="maritalStatus"
                    value={maritalStatus}
                    onChange={(e) => setMaritalStatus(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  >
                    <option value="" disabled>
                      Marital Status
                    </option>
                    <option value="Married">
                      Married
                    </option>
                    <option value="Unmarried">
                      Unmarried
                    </option>

                  </Form.Select>
                </div>
                {showDOB === true
                  &&
                  <div className="col-lg-4 mb-4 date-picker-appionment">
                    <label for="dob">Date Of Birth <span className="text-danger">*</span></label>
                    <DatePicker
                      id="dob"
                      defaultValue={birthDate}
                      className="ant-input css-dev-only-do-not-override-1gwfwyx ant-input-outlined date-picker"
                      format="DD/MM/YYYY"
                      onChange={(value) => setBirthDate(value?.format("DD/MM/YYYY"))}
                      disabled={selectedService !== "" ? false : true}
                      showNow={false}
                      disabledDate={(current) => current && current >= moment().endOf('day')}
                    />
                  </div>
                }
                {showTOB === true &&
                  <div className="col-lg-4 mb-4 date-picker-appionment">
                    <label for="tob">Time Of Birth <span className="text-danger">*</span></label>
                    <TimePicker id="tob" use12Hours onChange={(value) => setBirthTime(value?.format("HH:mm A"))} showSecond={false} showNow={false} />
                  </div>
                }
                {showPOB === true &&
                  <div className="col-lg-4 mb-4">
                    <label for="pob">Place Of Birth <span className="text-danger">*</span></label>
                    <Input
                      id="pob"
                      value={birthPlace}
                      onChange={(e) => setBirthPlace(e.target.value)}
                    />
                  </div>
                }
                {selectedService === "Vastu Shastra" &&
                  <div className="col-lg-4 mb-4">
                    <label for="vastuarea">Vastu Area <span className="text-danger">*</span></label>
                    <Form.Select
                      id="vastuarea"
                      aria-label="Area"
                      value={vastuArea}
                      onChange={(e) => setVastuArea(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Area
                      </option>
                      <option value="Home">
                        Home
                      </option>
                      <option value="Industrial">
                        Industrial
                      </option>
                      <option value="Office / Shop">
                        Office / Shop
                      </option>

                    </Form.Select>
                  </div>
                }


                <div className="col-lg-4 mb-4">
                  <label for="phone">Phone Number <span className="text-danger">*</span></label>
                  <PhoneInput
                    id="phone"
                    defaultCountry="in"
                    value={mobile}
                    disabled={selectedService !== "" ? false : true}
                    forceDialCode={true}
                    onChange={handleCountryChange}
                  />
                  {/* <Input
                    id="phone"
                    placeholder="Phone no"
                    value={mobile}
                    disabled={selectedService !== "" ? false : true}
                    onChange={(mobile) => {
                      if (mobile.target.value.length <= 10) {
                        setMobile(mobile.target.value);
                      }
                    }}
                  /> */}
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="email">Email Address <span className="text-danger">*</span></label>
                  <Input
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>

                <div className="col-lg-4 mb-4 date-picker-appionment">
                  <label for="appointmentDate">Preferred Date <span className="text-danger">*</span></label>

                  <DatePicker
                    id="appointmentDate"
                    className="ant-input css-dev-only-do-not-override-1gwfwyx ant-input-outlined date-picker"
                    format="DD/MM/YYYY"
                    onChange={handleChangeDate}
                    disabledDate={disabledDate}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>
                {halfHourprice !== null && <>
                  <div className="col-lg-4 mb-4 date-picker-appionment">
                    <label for="interval">Preferred Time <span className="text-danger">*</span></label>
                    <Form.Select
                      id="interval"
                      value={timeInterval}
                      onChange={(e) => {
                        setTimeInterval(e.target.value)
                        if (e.target.value === "half an hour") {
                          setSelectedAmount(halfHourprice)
                        } else {
                          setSelectedAmount(oneHourprice)
                        }
                      }}
                      disabled={selectedService !== "" ? false : true}
                    >
                      <option value="" disabled>
                        Time
                      </option> <option value="half an hour">
                        Half An Hour
                      </option>
                      {oneHourprice !== null &&
                        <option value="one hour">
                          One Hour
                        </option>}

                    </Form.Select>
                  </div>

                  <div className="col-lg-4 mb-4 date-picker-appionment">
                    <label for="slot">Preferred Time Slot <span className="text-danger">*</span></label>
                    <Form.Select
                      id="slot"
                      // aria-label="Interval"
                      value={selectedTime}
                      onChange={(e) => setSelectedTime(e.target.value)}
                      disabled={selectedService !== "" ? false : true}
                    >
                      <option value="" disabled>
                        Time Slots
                      </option>
                      <option value="09:00 am - 12:00 pm">
                        09:00 am - 12:00 pm
                      </option>
                      <option value="12:00 pm - 03:00 pm">
                        12:00 pm - 03:00 pm
                      </option>
                      <option value="03:00 pm - 06:00 pm">
                        03:00 pm - 06:00 pm
                      </option>

                    </Form.Select>
                  </div></>}
                {selectedService === "Handwriting and Signature Analysis" &&
                  <div>
                    <label for="imageUpload" className="mb-2">Handwriting or Signature Image <span className="text-danger">*</span></label>
                    <Upload
                      id="imageUpload"
                      customRequest={onaction}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={() => { }}
                      // onPreview={handlePreview}
                      onChange={handleChangeImage}
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </div>}
                {selectedService === "Palmistry" &&
                  <div>
                    <label className="mb-2" for="imageUpload2">Front Hand and Side Hand Image <span className="text-danger">*</span></label>
                    <Upload
                      id="imageUpload2"
                      customRequest={onaction}
                      listType="picture-card"
                      fileList={fileList}
                      // onPreview={handlePreview}
                      onPreview={() => { }}
                      onChange={handleChangeImage}
                    >
                      {/* at most 8 images can be selected  */}
                      {fileList.length >= 2 ? null : uploadButton}
                    </Upload>
                  </div>}


                <div className="col-12 mb-4 mt-3">
                  <label for="message" className="mb-2">Message</label>
                  <TextArea
                    id="message"
                    rows={5}
                    // placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>
                <div className="form-group col-lg-4 mb-4 pb-0">
                  <div className="captcha-box">
                    <input
                      type="text"
                      value={captcha}
                      disabled
                      className="form-control"
                    />
                    <div
                      className="captcha"
                      onClick={() => generateRandomCaptcha()}
                    >
                      <TfiReload />
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-6 mb-4">
                  <input
                    type="text"
                    value={enteredCaptcha}
                    onChange={(e) =>
                      setEnteredCaptcha(e.target.value.toUpperCase())
                    }
                    className="form-control"
                    placeholder="Enter Captcha *"
                  />
                </div>
                <div className="col-12 mb-3">
                  <div className="d-flex justify-content-end gap-2 read-more">
                    <a
                      className="theme-btn border-0"
                      onClick={handleValidateForm}
                    >
                      Submit
                    </a>
                    {/* <a
                      className="theme-btn border-0"
                      // onClick={handleValidateForm}
                      onClick={() => handlePayment(selectedAmount)}
                    >
                      Pay {selectedAmount !== null && <>-{" "}<MdCurrencyRupee />{selectedAmount}</>}
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}
