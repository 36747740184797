import React from "react";
import NavBar from "../nav/nav";
import { Helmet, HelmetProvider } from 'react-helmet-async';


import "./PrivacyPolicy.css";
import GetSeoData from "../API/GetSeoData";


export default function PrivacyPolicypage() {
    return (
        <>
            <GetSeoData type="Privacy Policy" id={null} />


            <section className="bg-white py-5 mb-4">
                <NavBar />
            </section>
            <section className="blogsection">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2 "><span className="px-2">Privacy </span>Policy </h1>
                        </div>
                        <div className="col-12">

                            {/* <img src={ariesblog} className="w-100 ariesimage" alt="Astrology Services"></img> */}
                            <div className="card-body ">
                                <h4 className="card-title mt-3">

                                </h4>

                                <p className="card-text text-dark">At Blessings Astrology, we are committed to protecting the privacy and confidentiality of our user's personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website and services, including but not limited to astrological services, numerology, tarot card readings, palmistry, reiki healing, and all other related services. Additionally, this policy covers payment-related policies and session booking procedures.

                                </p>

                                <p className="text-dark"><strong>Information Collection:</strong> </p>
                                <p className=" text-dark">We may collect personal information from you when you voluntarily provide it to us, such as when you sign up for an account, request a service, make a payment, or contact us with inquiries. This information may include your name, email address, birthdate, gender, billing information, and other relevant details.

                                </p>
                                <p className="text-dark"><strong>Use of Information:</strong> </p>
                                <p className="text-dark">We use the information we collect to provide and improve our services, personalize your experience, communicate with you, process payments, and fulfill your requests. This may include providing astrological readings, numerology consultations, tarot card readings, palmistry sessions, reiki healing, and other related services. We may also use your information to send you promotional materials or updates about our services, but you can opt-out of receiving these communications at any time.</p>
                                <p className="text-dark"><strong>Data Security:
                                </strong></p>
                                <p className="text-dark">We take reasonable measures to protect the security of your personal information and prevent unauthorized access, disclosure, alteration, or destruction. We use industry-standard encryption and security protocols to safeguard your data, and we regularly review and update our security practices to ensure compliance with best practices and applicable regulations.

                                </p>
                                <p className="text-dark"><strong>Payment Processing:</strong> </p>
                                <p className="text-dark">When you make a payment for our services, we may collect and process payment-related information, such as transaction screenshot, reference/ transaction other payment details. We use this information solely for the purpose of booking the slots for you. We do not store or retain your payment information after the transaction is complete.

                                </p>
                                <p className="text-dark"><strong>Session Booking:

                                </strong></p>
                                <p className="text-dark">When you book a session with us, we may collect information about your preferences, availability, and session requirements. This information is used to schedule and confirm your appointment and to provide you with the best possible service. We may also use your contact information to communicate with you about your session and to send you appointment reminders.


                                </p>


                                <p className="text-dark"><strong>Disclosure of Information:

                                </strong></p>
                                <p className="text-dark">We may disclose your personal information if required to do so by law or in response to valid legal requests, such as court orders or subpoenas. We may also disclose your information to third-party service providers who assist us in delivering our services, processing payments, or managing our website, but only to the extent necessary to perform these tasks on our behalf.


                                </p>

                                <p className="text-dark"><strong>Data Retention:


                                </strong></p>
                                <p className="text-dark">We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When we no longer need to retain your information, we will securely delete or anonymize it to prevent unauthorized access or use.


                                </p>

                                <p className="text-dark"><strong>Children's Privacy:


                                </strong></p>
                                <p className="text-dark">Our services are not intended for children under the age of 18, and we do not knowingly collect personal information from individuals under this age. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately to have it removed.



                                </p>

                                <p className="text-dark"><strong>Changes to This Policy:



                                </strong></p>

                                <p className="text-dark">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes to this policy by posting a notice on our website or by sending you an email notification. By continuing to use our services after such changes are made, you acknowledge and agree to the updated policy.




                                </p>

                                <p className="text-dark"><strong>Contact Us:


                                </strong></p>
                                <p className="text-dark">If you have any questions or concerns about this Privacy Policy or our practices regarding your personal information, please contact us at <a className="fs-6" href="mailto:surabhibhatnagar@blessingsastrology.com"><strong>surabhibhatnagar@blessingsastrology.com </strong></a> or call us at  <a className="fs-6" href="tel:+91 9821155251"><strong>+91 98211 55251</strong></a>




                                </p>
                                <p className="text-dark">By using our website and services, you consent to the collection, use, and disclosure of information as described in this Privacy Policy.
                                </p>


                            </div>


                        </div>
                    </div>
                </div>
            </section>


        </>

    );
}
