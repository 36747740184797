import Notiflix from "notiflix";
// var urlString = "https://globaltrendz.info/gt-api/";
var urlString = "https://api.bechofy.in/blessings-api/";
// var urlString = "http://localhost:3035/blessings-api/";

const PostApiCall = {
  postRequest(userData, url) {
    return fetch(urlString + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        "x-auth-token": JSON.parse(localStorage.getItem("access"))
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {

        if (response.status == 401) {
          localStorage.clear()
          // window.location.href = "/"
          Notiflix.Notify.failure("Session Expired. Login Again")
        } else {
          return response;
        }

      })
      .catch((error) => {
        return error;
      });
  },
};

export default PostApiCall;
