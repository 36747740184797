import { useEffect, useState } from "react";
import ariesblog from "../img/blog/blogaries.png";
import "./recentblogsection.css";
import GetApiCall from "../API/GETAPI";
import parse from "html-react-parser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Skeleton } from "antd";
export default function RecentBlogSection() {
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    GetBlogArticle();
  }, []);
  const GetBlogArticle = () => {
    GetApiCall.getRequest("GetBlogArticle").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        const latestBlog = obj.data.sort(
          (a, b) => a.fld_createdon - b.fld_createdon
        );
        setBlogData(latestBlog);
      });
    });
  };
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 200);
      });
    }
  }, []);
  return (
    <div
      className={
        scroll ? "active sticky-top recent-blogs" : "sticky-top recent-blogs"
      }>
      <h2 class="main-heading">Recent Blogs</h2>

      <div className="blog-btn-card px-0">
        {blogData.length > 0 ? (
          <ul>
            {blogData.slice(0, 4).map((data, index) => (
              <li className="d-flex mb-3 blog-side-card" key={index}>
                <div className="blog-side-image recent-image">
                  <Link
                    to={`/blog/${data.fld_route}`}
                  >
                    <img
                      src={data.fld_blogimage}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="blog-side-content blog-svg px-2">
                  {/* <div>
                                        <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>

                                        <span className=" p-1 my-3">{data.fld_createdon}</span>
                                    </div> */}
                  <div className="anchor-para px-1">
                    <Link
                      to={`/blog/${data.fld_route}`}
                    >
                      <h4>{data.fld_title}</h4>
                    </Link>
                    <p>{parse(data.fld_short_description)}</p>
                  </div>
                </div>
              </li>
            ))}
            <div className="text-center mt-4">
              <a href="/blogs" className="blog-view-all">View All</a>
            </div>
          </ul>
        ) : (
          <ul>
            <li className="d-flex mb-3 blog-side-card">
              <div className="blog-side-image recent-image">
                <Skeleton.Image className="img-fluid" alt="" />
              </div>
              <div className="blog-side-content blog-svg px-2">
                <div>
                  <span className=" p-1 my-3">
                    <Skeleton.Input />
                  </span>
                </div>
                <div className="anchor-para px-1">
                  <h4>
                    <Skeleton.Input />
                  </h4>
                  <p>
                    <Skeleton.Input />
                  </p>
                </div>
              </div>
            </li>
            <li className="d-flex mb-3 blog-side-card">
              <div className="blog-side-image recent-image">
                <Skeleton.Image className="img-fluid" alt="" />
              </div>
              <div className="blog-side-content blog-svg px-2">
                <div>
                  <span className=" p-1 my-3">
                    <Skeleton.Input />
                  </span>
                </div>
                <div className="anchor-para px-1">
                  <h4>
                    <Skeleton.Input />
                  </h4>
                  <p>
                    <Skeleton.Input />
                  </p>
                </div>
              </div>
            </li>
            <li className="d-flex mb-3 blog-side-card">
              <div className="blog-side-image recent-image">
                <Skeleton.Image className="img-fluid" alt="" />
              </div>
              <div className="blog-side-content blog-svg px-2">
                <div>
                  <span className=" p-1 my-3">
                    <Skeleton.Input />
                  </span>
                </div>
                <div className="anchor-para px-1">
                  <h4>
                    <Skeleton.Input />
                  </h4>
                  <p>
                    <Skeleton.Input />
                  </p>
                </div>
              </div>
            </li>
            <li className="d-flex mb-3 blog-side-card">
              <div className="blog-side-image recent-image">
                <Skeleton.Image className="img-fluid" alt="" />
              </div>
              <div className="blog-side-content blog-svg px-2">
                <div>
                  <span className=" p-1 my-3">
                    <Skeleton.Input />
                  </span>
                </div>
                <div className="anchor-para px-1">
                  <h4>
                    <Skeleton.Input />
                  </h4>
                  <p>
                    <Skeleton.Input />
                  </p>
                </div>
              </div>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
