import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from "react-bootstrap/Offcanvas";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaFacebookF,
  FaFacebookSquare,
  FaUser,
  FaWhatsappSquare,
  FaYoutube,
} from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import logo from "../img/blessing-logo.png";
import { NavLink } from "react-router-dom";
import "./nav.css";
import { IoMdCart, IoMdMail } from "react-icons/io";
// import {
//     Dropdown,
//     NavDropdown,
//   } from "react-bootstrap";
import { Dropdown } from "antd";
import { NavDropdown } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import SocialIcons from "../WrapperComponents/SocialIcons";
import { IoCall } from "react-icons/io5";
import GetApiCall from "../API/GETAPI";
import PostApiCall from "../API/POSTAPI";
import { store } from "../Helper/Store";
import Notiflix from "notiflix";
import LoginModal from "../Login/LoginModal";
import ChangePassword from "../Login/ChangePassword";
import LogoutModal from "../Login/LogoutModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function NavBar() {
  const history = useHistory();
  const [scroll, setScroll] = useState(false);
  const {
    cart,
    setcart,
    setcartamount,
    clientData,
    loggedIn,
    setLoggedIn,
    siteHeader,
    setCartItems,
  } = useContext(store);

  const [loginModalStatus, setLoginModalStatus] = useState(false);
  const [modalSide, setModalSide] = useState("");
  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [canLogout, setCanLogout] = useState(false);

  useEffect(() => {
    getCustomerDetails();
  }, [loggedIn]);
  const getCustomerDetails = () => {
    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));
    if (loginDetails !== null) {
      setLoggedIn(true);
    }
  };
  // login specific functions
  const handleToggleModal = () => {
    setLoginModalStatus(false);
  };
  const handleChangeModalSide = (e) => {
    setModalSide(e);
  };
  const clearItemDetails = () => {
    localStorage.removeItem("itemdetails");
  };
  const handleProfileClick = () => {
    history.push("/customer-profile");
  };
  const changePssword = () => {
    setOpenChangePwd(true);
  };
  // change password specific functions
  const handleTogglePwdModal = () => {
    setOpenChangePwd(false);
  };
  const handleToggleLogout = () => {
    setCanLogout(false);
  };
  const handleOpenCart = () => {
    if (loggedIn) {
      // console.log(loggedIn)
      // return <Redirect to="/cart" />
      history.push("/cart");
    } else {
      setModalSide("Log");
      setLoginModalStatus(true);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <span
          onClick={() => {
            setModalSide("Log");
            setLoginModalStatus(true);
            //   clearItemDetails();
          }}
        >
          Login
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={() => {
            setModalSide("Reg");
            setLoginModalStatus(true);
            //   clearItemDetails();
          }}
        >
          Sign Up
        </span>
      ),
    },
  ];

  const loggedInitems = [
    {
      key: "1",
      label: <span onClick={handleProfileClick}>Profile</span>,
    },
    {
      key: "2",
      label: <span onClick={changePssword}>Change Password</span>,
    },
    {
      key: "3",
      label: <span onClick={() => setCanLogout(true)}>Log Out</span>,
    },
  ];
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }

    getCartdetails();
  }, []);
  const onLogout = () => {
    if (localStorage.getItem("CustomerData") !== null) {
      localStorage.removeItem("CustomerData");
      localStorage.removeItem("OrderData");
      setcart(0);
      setcartamount(0);
      setCartItems([]);
      history.push("/");
      setLoggedIn(false);
      setCanLogout(false);
      Notiflix.Loading.remove();
    } else {
      Notiflix.Notify.failure("Something went wrong");
    }
  };

  const getCartdetails = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        // console.log(res1);
        if (res1.data) {
          setCartItems(res1.data);
          Notiflix.Loading.remove();
          setcart(res1?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          // setcartamount(res1.data[0]?.fld_cart_amount_preview);
        }
      });
  };
  return (
    <div
      className={scroll ? "nav-container fixed-top bg-white" : "nav-container"}
    >
      <ChangePassword
        isOpen={openChangePwd}
        handdleToggleModal={handleTogglePwdModal}
      />
      <LogoutModal
        show={canLogout}
        onHide={handleToggleLogout}
        onLogout={onLogout}
      />
      <LoginModal
        isOpen={loginModalStatus}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      />
      <Container
        fluid="xxl"
        className="border-bottom py-3 px-xl-5 px-xxl-0 d-none d-lg-block"
      >
        <Row>
          <Col xs={6} lg={6} xxl={6}>
            <Navbar.Brand href="/">
              <img src={logo} className="img-fluid"></img>
            </Navbar.Brand>
          </Col>
          <Col
            xs={6}
            md={3}
            lg={6}
            className=" d-flex align-items-center  justify-content-end top-sm-icons"
          >
            {/* <a href="mailto:surabhibhatnagar@blessingsastrology.com"><IoMdMail /></a>
                            <a href="https://wa.me/919821155251"><FaWhatsappSquare /></a>
                            <a href="https://m.facebook.com/blessings.astrologysolutions?_rdr" target={"_blank"}><FaFacebookSquare /></a>
                            <a href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag" target={"_blank"}><FaInstagramSquare /></a>
                            <a href="https://youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ" target={"_blank"}><FaYoutubeSquare /></a>
                            <a className="pe-lg-0" href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/" target={"_blank"}><FaLinkedin /></a> */}
            {/* <Dropdown >
                <Dropdown.Toggle
                  // onClick={onProfileClick}
                  className="login-icon"
                  id="dropdown-basic"
                  >
                  <a href='' className='user-dropdown'>
                            </a>
                            <FaUser />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn === false && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide('Log')
                          setLoginModalStatus(true)
                        //   clearItemDetails();
                        }}
                      >Login</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide('Reg')
                          setLoginModalStatus(true)
                        //   clearItemDetails();
                          
                        }}
                      >Sign Up</Dropdown.Item>
                    </>
                  )}
                  {loggedIn === true && (
                    <>
                      <Dropdown.Item onClick={handleProfileClick}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={changePssword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setCanLogout(true)}>
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="d-flex d-lg-none">
                <Dropdown.Toggle
                //   onClick={onProfileClick}
                  // className="login-icon"
                  id="dropdown-basic">
                  <a href='javascript:void(0)' className='user-dropdown'>
                                <FaUser />
                            </a>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn === false && (
                    <>
                      <Dropdown.Item onClick={() => {
                        setModalSide('Log')
                        setLoginModalStatus(true)
                        clearItemDetails()
                      }}>
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        setModalSide("Reg")
                        setLoginModalStatus(true)
                        clearItemDetails()
                      }}>
                        Sign Up
                      </Dropdown.Item>
                    </>
                  )}
                  {loggedIn === true && (
                    <>
                      <Dropdown.Item onClick={handleProfileClick}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={changePssword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setCanLogout(true)}>
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown> */}
            {loggedIn === true && (
              <Dropdown menu={{ items: loggedInitems }} placement="bottomRight">
                <a href="javascript:void(0)" className="user-dropdown">
                  <FaUser />
                </a>
              </Dropdown>
            )}

            {loggedIn === false && (
              <Dropdown menu={{ items }} placement="bottomRight">
                <a href="javascript:void(0)" className="user-dropdown">
                  <FaUser />
                </a>
              </Dropdown>
            )}

            <a href="/cart" className="cart-icon">
              <IoMdCart />
              <span>{cart} Items</span>
            </a>
          </Col>
        </Row>
      </Container>
      <Navbar expand="lg" sticky="top">
        <Container fluid="xxl" className="px-xl-5 px-xxl-0">
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}

          <div className="d-flex d-lg-none">
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand`}
              className="border-0"
            />
            <Navbar.Brand href="/">
              <img src={logo} className="img-fluid d-lg-none ms-1"></img>
            </Navbar.Brand>
          </div>

          <div className=" d-flex align-items-center  justify-content-end top-sm-icons d-lg-none">
            {loggedIn === true && (
              <Dropdown menu={{ items: loggedInitems }} placement="bottomRight">
                <a href="javascript:void(0)" className="user-dropdown">
                  <FaUser />
                </a>
              </Dropdown>
            )}

            {loggedIn === false && (
              <Dropdown menu={{ items }} placement="bottomRight">
                <a href="javascript:void(0)" className="user-dropdown">
                  <FaUser />
                </a>
              </Dropdown>
            )}
            <a href="/cart" className="cart-icon">
              <IoMdCart />
              <span>0 Item</span>
            </a>
          </div>

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="start"
          >
            <Offcanvas.Header className="logo-image" closeButton>
              <img src={logo} className="img-fluid"></img>
            </Offcanvas.Header>
            <Offcanvas.Body className="me-md-auto">
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {/* <Nav.Link href="/" className="ps-lg-0 nav-link">
                  Home
                </Nav.Link> */}
                {/* <Nav.Link href="about-us">About Us</Nav.Link> */}
                <Nav.Link href="/services">Our Services</Nav.Link>
                <Nav.Link href="/horoscope-prediction">Horoscope </Nav.Link>
                {/* <Nav.Link href="/our-products">Our Products</Nav.Link> */}
                <NavDropdown
                  className=""
                  data-toggle="dropdown"
                  title="Shop Now"
                  id="offcanvasNavbarDropdown-expand-sm"
                  renderMenuOnMount={true}
                >
                  {/* <NavDropdown.Item>Books</NavDropdown.Item> */}
                  <NavDropdown.Item href="/ct/products/1">
                    Products
                  </NavDropdown.Item>
                  <NavDropdown.Item>Books</NavDropdown.Item>

                  <NavDropdown.Item>Healing Jewellery</NavDropdown.Item>
                  <NavDropdown.Item>Remedial Items</NavDropdown.Item>
                </NavDropdown>

                <Nav.Link href="/blogs">Blogs</Nav.Link>
                {/* <Nav.Link href="/gallery">Gallery</Nav.Link>
                                    <Nav.Link href="/media">Media</Nav.Link> */}
                <Nav.Link href="/our-books">Our Books</Nav.Link>

                {/* <Nav.Link href="/contact-us">Book An Appointment</Nav.Link> */}
                <Nav.Link to="/contact-us" className="nav-link">
                  Conatct Us
                </Nav.Link>
              </Nav>
              <div className="d-flex contact-links justify-content-start my-2 d-lg-none d-block">
                <IoCall className="linkk" />
                {/* <a href={`tel:${clientData[0]?.mobile}`}>
                                {clientData[0]?.mobile}
                            </a> */}
              </div>
              <div className="d-flex contact-links justify-content-start mb-3 d-lg-none d-block">
                <IoMdMail className="linkk" />
                {/* <a href={`mailto:${clientData[0]?.email}`}>
                                {clientData[0]?.email}
                            </a> */}
              </div>
              <h6 className="d-lg-none d-block">Follow us on</h6>
              <ul className="d-flex contact-links my-2 ps-0 justify-content-start linkk d-lg-none d-block">
                {/* <SocialIcons
                                    link={clientData[0]?.facebook}
                                    link="https://m.facebook.com/blessings.astrologysolutions?_rdr"
                                    target="_blank"
                                    logo={<FaFacebookF />}
                                /> */}
                <a
                  className="ms-lg-2"
                  href="https://m.facebook.com/blessings.astrologysolutions?_rdr"
                  target={"_blank"}
                >
                  <FaFacebookSquare />
                </a>
                {/* <SocialIcons
                                    link={clientData[0]?.instagram}
                                    target="_blank"
                                    logo={<FaInstagramSquare />}
                                />
                                <SocialIcons
                                    link={clientData[0]?.twitter}
                                    target="_blank"
                                    logo={<FaYoutubeSquare />}
                                />
                                <SocialIcons
                                    link={clientData[0]?.linkedin}
                                    target="_blank"
                                    logo={<FaLinkedin />}
                                />

                                <SocialIcons
                                    link={clientData[0]?.youtube}
                                    target="_blank"
                                    logo={<FaYoutube />}
                                /> */}
                <a
                  className="ms-2"
                  href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag"
                  target={"_blank"}
                >
                  <FaInstagramSquare />
                </a>
                <a
                  className="ms-2"
                  href="https://youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ"
                  target={"_blank"}
                >
                  <FaYoutubeSquare />
                </a>
                <a
                  className="ms-2"
                  href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/"
                  target={"_blank"}
                >
                  <FaLinkedin />
                </a>
              </ul>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <Nav className="d-md-flex d-lg-none order-1 order-md-2 d-flex flex-row nav-sm-icon d-none">
            <Nav.Link
              href="https://m.facebook.com/blessings.astrologysolutions?_rdr"
              target={"_blank"}
            >
              <FaFacebookSquare />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag"
              target={"_blank"}
            >
              <FaInstagramSquare />
            </Nav.Link>
            <Nav.Link
              href="https://youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ"
              target={"_blank"}
            >
              <FaYoutubeSquare />
            </Nav.Link>
            <Nav.Link
              className="pe-lg-0"
              href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/"
              target={"_blank"}
            >
              <FaLinkedin />
            </Nav.Link>
            <Nav.Link href="https://wa.me/919821155251">
              <FaWhatsappSquare />
            </Nav.Link>
            <Nav.Link href="mailto:surabhibhatnagar@blessingsastrology.com">
              <IoMdMail />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavBar;
