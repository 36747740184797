import React, { useState } from "react";
import logo from "../img/logo.png";
import OrCode from "../img/qr-code.jpeg";
import "./paymentpage.css";
import Notiflix from "notiflix";
import PostApiCall from "../API/POSTAPI";
import NavBar from "../nav/nav";
import sbg from "../img/2.png";
import GetSeoData from "../API/GetSeoData";
function Paymentpage() {
  // fileupload
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileBase64String, setfileBase64String] = useState("");
  const onFileChange = (e) => {
    // if (e.target.files[0].type == "application/pdf") {
    setSelectedFile(e.target.files[0]);
    encodeFileBase64(e.target.files[0]);

    // } else {
    //   Notiflix.Notify.failure("file should be in pdf format");
    // }
  };

  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64String(attachment);
        console.log(attachment);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };

  // state for loader
  const [Loader, setLoader] = useState(false);

  const [Enquiry, setEnquiry] = useState({
    name: "",
    mobile: "",
    email: "",
    transitionid: "",
    file: "",
    attachment: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  const SendEnquiry = (action) => {
    if (Enquiry.name != "") {
      if (Enquiry.email == "" || Enquiry.EmailRegex.test(Enquiry.email)) {
        if (Enquiry.mobile != "") {
          if (Enquiry.mobile.length == 10) {
            // if (Enquiry.transitionid != "") {
            {
              setLoader(true);
              console.log(fileBase64String);
              PostApiCall.postRequest(
                {
                  name: Enquiry.name,
                  mobile: Enquiry.mobile,
                  email: Enquiry.email,
                  message: Enquiry.transitionid,
                  image: fileBase64String,
                },
                "blessingpayment"
              ).then((results2) =>
                results2.json().then((obj2) => {
                  if (results2.status == 200 || results2.status == 201) {
                    setLoader(false);
                    // setShow(false);
                    Notiflix.Notify.success(
                      "Thankyou, we will get back to you shortly."
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  }
                })
              );
            }
          }
          //    else {
          //     Notiflix.Notify.failure("Please enter transition id");
          //   }
          // }
          else {
            Notiflix.Notify.failure("Please enter valid mobile number");
          }
        } else {
          Notiflix.Notify.failure("Please enter mobile number");
        }
      } else {
        Notiflix.Notify.failure("Please enter valid email");
      }
    } else {
      Notiflix.Notify.failure("Please enter name");
    }
  };

  return (
    <>
      <GetSeoData type="book slot" id={null} />
      <section className="inner-section paymen-section">
        <NavBar />
        <div className="container">
          {/* <div className="row">
            <div className="col-12 text-center py-3">
              <img src={logo} />
            </div>
          </div> */}
          <div className="row my-4 justify-content-center align-items-center">
            <div className="col-12 my-4 text-center">
              <h4>INSTRUCTIONS</h4>

              <ol className="text-start text-md-center d-md-flex w-100 payment-inst justify-content-center mt-3">
                <li>
                  <p>Scan the QR code</p>
                </li>
                <li>
                  <p>Make the payment</p>
                </li>
                <li>
                  <p>Take a screenshot</p>
                </li>
                <li>
                  <p>Upload the screenshot to the form</p>
                </li>
              </ol>
            </div>
          </div>
          <div className="row my-4 bg-light bordwe-rounded">
            <div className="col-lg-6 px-lg-0 d-none d-lg-block">
              <img src={sbg} className="img-fluid w-100 pay-img" />
            </div>
            <div className="col-md-6 text-center payment-form py-4 px-lg-4 ">
              <img src={OrCode} className="payment-form-img" />
              <p className="text-center">
                <strong> Name</strong> - Surabhi Bhatnagar
                <br />
                <strong>UPI Handle</strong> - 9810699400@paytm
              </p>

              <div className="mt-4 text-start">
                <label for="Name" class="form-label blessing-form-label">
                  Name <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  value={Enquiry.name}
                  onChange={(e) =>
                    setEnquiry({ ...Enquiry, name: e.target.value })
                  }
                />
              </div>
              <div className="mt-4 text-start">
                <label for="Name" class="form-label blessing-form-label">
                  Mobile No. <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  value={Enquiry.mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 10)
                      setEnquiry({
                        ...Enquiry,
                        mobile: e.target.value.replace(/\D/g, ""),
                      });
                  }}
                />
              </div>
              <div className="mt-4 text-start">
                <label for="Name" class="form-label blessing-form-label">
                  Email
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  value={Enquiry.email}
                  onChange={(e) =>
                    setEnquiry({ ...Enquiry, email: e.target.value })
                  }
                />
              </div>
              <div className="mt-4 text-start">
                <label for="Name" class="form-label blessing-form-label">
                  Payment Transaction ID <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  value={Enquiry.transitionid}
                  onChange={(e) =>
                    setEnquiry({ ...Enquiry, transitionid: e.target.value })
                  }
                />
              </div>
              <div className="mt-4 text-start">
                <label for="Name" class="form-label blessing-form-label">
                  Upload Screenshot <span class="text-danger">*</span>
                </label>
                <input
                  type="file"
                  class="form-control"
                  id="file"
                  name="File"
                  onChange={onFileChange}
                />
              </div>
              <div className="mt-4 text-center">
                {Loader == true ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border"
                      role="status"
                      style={{ width: "1.4rem", height: "1.4rem" }}>
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="button button-2"
                    onClick={() => {
                      SendEnquiry();
                    }}>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Paymentpage;
