import React, { useContext, useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Button, message, Space } from "antd";
import Notiflix from "notiflix";
import Parser from "html-react-parser";
import { Modal, Row } from "react-bootstrap";
import { LuIndianRupee } from "react-icons/lu";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";
// import Innerbanner from "../InnerBanner/InnerBanner";
import { store } from "../Helper/Store";
import PostApiCall from "../API/POSTAPI";
import moment from "moment";
const { ToWords } = require("to-words");

// import InvoiceForm from "../InvoiceForm"
// import nodata from "../../assets/img/nodatafound.png";

export default function CustomerOrderList() {
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { setTotalOrders } = useContext(store);
  const [openModalForDownloadInvoice, setOpenModalForDownloadInvoice] =
    useState(false);
  const [idToFetch, setIdToFetch] = useState("");
  const [visibleProducts, setVisibleProducts] = useState(3);
  const [paymentStatus, setPaymentStatus] = useState("finish");
  const [dispatchStatus, setDispatchStatus] = useState("wait");

  // order form related states
  const [amountInWords, setAmountInWords] = useState(null);
  const [clientPhoneNumber, setClientPhoneNumber] = useState(null);
  const [clientEmail, setClientEmail] = useState("");
  const [clientLogo, setClientLogo] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientGST, setClientGST] = useState("");
  const [clientWesite, setClientWesite] = useState("");
  const [orderData, setOrderData] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientCountry, setClientCountry] = useState("");
  const [clinetPincode, setClinetPincode] = useState("");
  const [clientState, setClientState] = useState("");
  const [clientPAN, setClientPAN] = useState("");
  const [discount, setDiscount] = useState(0);
  const [invoiceModalView, setInvoiceModalView] = useState(false);
  const [visibleProductList, setVisibleProductList] = useState([]);

  // order form related functions
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    },
  });
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleShowMore = () => {
    setVisibleProducts((prevState) => prevState + 3);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Notiflix.Loading.init({
      svgColor: "#0f3d74",
    });

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    // function to get order details
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${parseInt(
          CustomerDetails[0].fld_customerid
        )}`,
        select: `*`,
      },
      "GetCustomerOrderDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setOrderHistoryData(obj.data);
          setVisibleProductList(obj.data)
          setTotalOrders(obj.data[0]);
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);



  // invoice download
  const downloadInvoice = async (data) => {
    setInvoiceModalView(true)
    await PostApiCall.postRequest(
      {
        trxid: data.fld_payment_trx_num,
        orderid: data.fld_order_id,
      },
      "GetCustomerOrderForm"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setOrderData(obj.data);
          obj.data.map((data, i) => {
            if (i === 0) {
              let words = toWords.convert(Math.round(data.Total_Inclusive_Tax));
              let awords = capitalizeWords(words);
              setAmountInWords("Rupees " + awords + " Only");
              setClientName(data.client_company);
              setClientLogo(data.fld_storelogo);
              setClientPhoneNumber(data.client_mobile);
              setClientAddress(data.client_address);
              setClientCity(data.client_city);
              setClientCountry(data.client_country);
              setClientEmail(data.client_mail);
              setClinetPincode(data.client_pincode);
              setClientState(data.client_state);
              setClientWesite(data.client_website);
              setClientGST(data.client_gst);
              setClientPAN(data.client_pan);
            }
          });
          Notiflix.Loading.remove();
          const orderDate = (obj.data[0].fld_order_date).replace(/\//g, "-")
          setTimeout(() => {
            DownloadInvoicePdf(orderDate, obj.data[0].fld_payment_trx_num)
          }, 1000)
        }
      })
    );

  }
  const DownloadInvoicePdf = (orderDate, trxNum) => {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("orderform");
    var opt = {
      margin: 1,
      filename: `${orderDate}-${trxNum}`,
      image: { type: "png", quality: 1.9 },
      html2canvas: {
        scale: 1.1,
        useCORS: true,
        allowTaint: true,
        proxy: null,
        dpi: 192,
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
        compress: true,
      },
      //   pagebreak: {avoid: ‘div’}
    };
    // //
    // New Promise-based usage:
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        setInvoiceModalView(false)
      });
  };
  const handleMenuClick = (e) => {
    let sortedData;

    if (e.key == 1) {
      // Create a new sorted array based on order amount
      sortedData = [...orderHistoryData].sort((a, b) => a.fld_order_amount - b.fld_order_amount);
    } else if (e.key == 2) {
      // Create a new sorted array based on order date
      sortedData = [...orderHistoryData].sort((a, b) => a.fld_order_date - b.fld_order_date);
    }

    // Update state with the new sorted data
    setVisibleProductList(sortedData);
  };

  const items = [
    {
      label: "Amount",
      key: 1,
    },
    {
      label: "Order Date",
      key: 2,
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <>
      {/* <Innerbanner /> */}
      <section className="spacing mt-5 pt-lg-5 blank-section ">
        <div className="container-fluid mt-lg-4">
          <Row>
            <div className="col-12 mb-4">
              <div className="d-flex justify-content-between align-items-end mb-4">
                <h2 className="mb-0">My Orders</h2>
                <div className="d-flex align-items-end">
                  <Dropdown menu={menuProps}>
                    <Button>
                      <Space>
                        Sort by
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                  {/* <Link to="/view-all-orders">
                            <button className="theme-btn border-0 ms-3">View All</button>
                        </Link> */}
                </div>
              </div>
              {visibleProductList && visibleProductList?.length > 0 ? (
                visibleProductList.map((data) => {
                  return (
                    <>
                      <div className="order-card row mb-4">
                        <div className="col-12 d-flex justify-content-md-end order-card-btns gap-3">
                          {/* <button className="theme-btn border-0">Track Order</button> */}
                          {/* <span className="theme-btn" >
                      Download Invoice
                    </span> */}
                          {/* <DownloadInvoice id={data.fld_order_id} txnId={data.fld_payment_trx_num} /> */}
                          <span
                            className="theme-btn"
                            onClick={() => downloadInvoice(data)}>
                            Download Order Form
                          </span>
                        </div>
                        <div className="col-lg-3">
                          <div className="border">
                            <div className="fields">
                              <lable>Order No:</lable>
                              <p>{data.fld_order_number}</p>
                            </div>
                            <div className="fields">
                              <lable>Order Date:</lable>
                              <p>{data.fld_order_date}</p>
                            </div>
                            <div className="fields">
                              <lable>Order Type:</lable>
                              <p>{data.fld_payment_mode}</p>
                            </div>
                            <div className="fields">
                              <lable>No. of Items:</lable>
                              <p>{data.total_items}</p>
                            </div>
                            <div className="fields">
                              <lable>Amount:</lable>
                              <p>
                                <LuIndianRupee />{" "}
                                {parseFloat(data.fld_order_amount).toFixed(2)}
                              </p>
                            </div>
                            <div className="fields">
                              <lable>Payment Mode:</lable>
                              <p>{data.fld_payment_mode}</p>
                            </div>
                            <div className="fields">
                              <lable>Status:</lable>
                              <p>{data.fld_order_status}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                              clickable: true,
                            }}
                            breakpoints={{
                              640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                              768: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                              },
                              1024: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                              },
                            }}
                            modules={[Pagination]}
                            className="mySwiper">
                            {JSON.parse(data.item_details)?.map((dat) => {
                              return (
                                <SwiperSlide>
                                  <div className="order-inner-card">
                                    <img
                                      src={dat.fld_imageurl}
                                      className="img-fluid"
                                    />
                                    <div className="content">
                                      <p>{dat.fld_variantname}</p>
                                      <div>
                                        <span>Quantity: </span>
                                        {dat.fld_quantity}
                                      </div>
                                      <div>
                                        <span>Amount: </span>
                                        <LuIndianRupee />{" "}
                                        {parseFloat(
                                          dat.fld_website_discount_price
                                        ).toFixed(2)}
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="w-100 text-center">
                  {/* <img className="nodata-icon" src={nodata} /> */}
                  <p className="text-center my-2 my-2 fs-5 fw-bold ps-2">
                    Order not found
                  </p>
                </div>
              )}
            </div>
            <Modal show={invoiceModalView} size="lg" centered>
              <Modal.Body>
                <div
                  id="orderform"
                  style={{
                    maxWidth: "190mm",
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                  }}>
                  <table
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      bottom: "0px",
                      borderRightColor: "#000",
                      marginTop: "30px",
                      borderCollapse: "collapse",
                      border: "2px solid #000",
                    }}
                    cellSpacing="0"
                    cellPadding="0">
                    <tbody>
                      <tr style={{ borderBottom: "2px solid #000" }}>
                        <td
                          colSpan="2"
                          style={{
                            width: "20%",
                            verticalAlign: "middle",
                            borderRight: "2px solid #000",
                            padding: "4px",
                          }}>
                          <img
                            src={`${clientLogo}`}
                            alt="logoorderform"
                            style={{
                              width: "100%",
                              marginRight: "auto",
                              marginLeft: "auto",
                              verticalAlign: "middle",
                            }}
                          />{" "}
                        </td>
                        <td colSpan="8" style={{ width: "80%" }}>
                          <h2
                            style={{
                              textAlign: "right",
                              fontSize: "20px",
                              padding: "1% 2% 0 0",
                              color: "#000",
                              fontWeight: "600",
                              marginBottom: "-6px",
                            }}>
                            Order Form
                          </h2>
                          <h2
                            style={{
                              textAlign: "left",
                              fontSize: "25px",
                              fontWeight: "bold",
                              padding: "0 0 0% 2%",
                              color: "#000",
                              margin: "0",
                            }}>
                            {clientName}
                          </h2>
                          <p
                            style={{
                              textAlign: "left",
                              padding: "0 0 0% 2%",
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "600",
                              margin: "0 0 8px",
                            }}>
                            {clientAddress}
                            <br />
                            {/* {clientCity},  {clientState} {clinetPincode == "" ? "" : - clinetPincode}, {" "}{clientCountry}<br /> */}
                            Phone : {clientPhoneNumber}
                            <span style={{ padding: "1%" }}>|</span>Email :{" "}
                            {clientEmail}
                            <br />
                            {clientGST && (
                              <>
                                <span>GSTIN : {clientGST}</span>
                                <span style={{ padding: "1%" }}>|</span>
                              </>
                            )}
                            {clientPAN && <span>PAN : {clientPAN}</span>}
                          </p>
                        </td>
                      </tr>
                      {orderData.length != 0
                        ? orderData.map((data, i) => {
                          if (i === 0) {
                            return (
                              <tr
                                style={{
                                  fontSize: "14px",
                                  borderBottom: "2px solid #000",
                                }}>
                                <td
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "1%",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    borderRight: "2px solid #000",
                                  }}>
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      color: "#000",
                                    }}>
                                    Order Date
                                  </span>
                                </td>
                                <td
                                  colSpan="5"
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "1%",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    color: "#000",
                                    fontWeight: "500",
                                    borderRight: "2px solid #000",
                                  }}>
                                  {data.fld_order_date}
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "1%",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    borderRight: "2px solid #000",
                                  }}>
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      color: "#000",
                                    }}>
                                    Order No.
                                  </span>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "1%",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    color: "#000",
                                    fontWeight: "500",
                                  }}>
                                  {data.fld_orderNumber}
                                </td>
                              </tr>
                            );
                          }
                        })
                        : ""}
                      <tr
                        className="success"
                        style={{ borderBottom: "2.5px solid #000" }}>
                        <td
                          colSpan="6"
                          style={{
                            padding: "3px",
                            fontWeight: "700",
                            fontSize: "17px",
                            textAlign: "center",
                            color: "#000",
                            width: "50%",
                            borderRight: "2px solid #000",
                          }}>
                          Billing Address
                        </td>
                        <td
                          colSpan="6"
                          style={{
                            padding: "3px",
                            color: "#000",
                            fontWeight: "700",
                            fontSize: "17px",
                            textAlign: "center",
                            width: "50%",
                          }}>
                          Delivery Address
                        </td>
                      </tr>
                      {orderData.length != 0
                        ? orderData.map((data, i) => {
                          if (i === 0) {
                            return (
                              <tr>
                                {() => setDiscount(data.fld_discount_amount)}
                                <td
                                  colSpan="6"
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "1%",
                                    paddingTop: "2px",
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    borderRight: "2px solid #000",
                                  }}>
                                  <p
                                    className="billing-pdf"
                                    style={{ padding: "0", margin: "0" }}>
                                    {Parser(
                                      (
                                        "<p>" +
                                        data.fld_billing_address_all +
                                        "</p>"
                                      )
                                        .replace(/font-family/g, "")
                                        .replace(/<br\/?>/gi, " ")
                                    )}
                                  </p>
                                </td>
                                <td
                                  colSpan="6"
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "1%",
                                    paddingTop: "2px",
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}>
                                  <p
                                    className="billing-pdf"
                                    style={{ padding: "0", margin: "0" }}>
                                    {Parser(
                                      (
                                        "<p>" +
                                        data.fld_shipping_address_all +
                                        "</p>"
                                      )
                                        .replace(/font-family/g, "")
                                        .replace(/<br\/?>/gi, " ")
                                    )}
                                  </p>
                                </td>
                              </tr>
                            );
                          }
                        })
                        : ""}
                    </tbody>
                  </table>
                  <table
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRightColor: "#000",
                      borderTop: "hidden",
                      borderBottom: "2px solid #000",
                      borderLeft: "2px solid #000",
                      borderRight: "2px solid #000",
                    }}
                    cellSpacing="0"
                    cellPadding="0">
                    <tbody>
                      <tr
                        className="success"
                        style={{
                          fontSize: "14px",
                          borderBottom: "2.5px solid #000",
                        }}>
                        <td
                          style={{
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            textAlign: "center",
                            color: "#000",
                            borderRight: "2px solid #000",
                            width: "6%",
                          }}>
                          <span
                            style={{ fontWeight: "bold", fontSize: "14px" }}>
                            SI.{" "}
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            textAlign: "center",
                            color: "#000",
                            borderRight: "2px solid #000",
                            width: "36%",
                          }}>
                          <span
                            style={{ fontWeight: "bold", fontSize: "14px" }}>
                            Item Description
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            textAlign: "center",
                            color: "#000",
                            borderRight: "2px solid #000",
                            width: "90px",
                          }}>
                          <span
                            style={{ fontWeight: "bold", fontSize: "14px" }}>
                            Unit Price
                          </span>
                        </td>
                        <td
                          style={{
                            padding: "4px",
                            textAlign: "center",
                            color: "#000",
                            borderRight: "2px solid #000",
                            width: "90px",
                          }}>
                          <span
                            style={{ fontWeight: "bold", fontSize: "14px" }}>
                            Qty
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            textAlign: "center",
                            color: "#000",
                            borderRight: "2px solid #000",
                            width: "120px",
                          }}>
                          <span
                            style={{ fontWeight: "bold", fontSize: "14px" }}>
                            Net Amount
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            textAlign: "center",
                            color: "#000",
                            borderRight: "2px solid #000",
                          }}>
                          <span
                            style={{ fontWeight: "bold", fontSize: "14px" }}>
                            Tax
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            textAlign: "center",
                            color: "#000",
                          }}>
                          <span
                            style={{ fontWeight: "bold", fontSize: "14px" }}>
                            Amount
                          </span>
                        </td>
                      </tr>
                      {orderData.length > 0 &&
                        orderData.map((data, i) => {
                          return (
                            <tr
                              style={{
                                fontSize: "14px",
                                borderBottom: "2px solid #000",
                              }}>
                              <td
                                style={{
                                  color: "#000",
                                  borderRight: "2px solid #000",
                                }}>
                                {i + 1}
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  borderRight: "2px solid #000",
                                  padding: "1%",
                                  color: "#000",
                                  lineHeight: "20px",
                                }}>
                                {" "}
                                <span
                                  style={{ color: "#000", fontWeight: "800" }}>
                                  {/* {data.item_name} - */}
                                  {data.variant_name}
                                </span>
                                <br />
                                {/* {data.fld_ord_info?.split(",").length <= 0 ?
																<div style={{ color: '#000', fontWeight: '600' }}>
																	{data.fld_ord_info.split("#")[0]}-{data.fld_ord_info.split("#")[1]}<br />
																</div>
																: data.fld_ord_info?.split(",").map((info) => {
																	return (
																		<div style={{ color: '#000', fontWeight: '600' }}>
																			{info.split("#")[0]} - {info.split("#")[1]}<br />
																		</div>
																	)
																})
															} */}
                                {data.fld_hsncode != "na" ||
                                  data.fld_hsncode != "NA" ||
                                  (data.fld_hsncode && (
                                    <span
                                      style={{
                                        color: "#000",
                                        fontWeight: "600",
                                      }}>
                                      HSN Code - {data.fld_hsncode}
                                    </span>
                                  ))}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  color: "#000",
                                  borderRight: "2px solid #000",
                                  verticalAlign: "top",
                                  paddingTop: "10px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}>
                                {parseFloat(data.fld_mrp).toFixed(2) ===
                                  parseFloat(data.fld_selling_price).toFixed(
                                    2
                                  ) ? (
                                  <div>
                                    {parseFloat(data.fld_selling_price).toFixed(
                                      2
                                    )}
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <strike>
                                        {parseFloat(data.fld_mrp).toFixed(2)}
                                      </strike>
                                    </div>
                                    <div>
                                      {parseFloat(
                                        data.fld_selling_price
                                      ).toFixed(2)}
                                    </div>
                                  </>
                                )}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  color: "#000",
                                  borderRight: "2px solid #000",
                                  verticalAlign: "top",
                                  paddingTop: "10px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}>
                                {data.fld_quantity}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#000",
                                  borderRight: "2px solid #000",
                                  verticalAlign: "top",
                                  paddingTop: "10px",
                                  padding: "1%",
                                  width: "100px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}>
                                {parseFloat(
                                  parseFloat(data.fld_netvalue).toFixed(2) *
                                  parseFloat(data.fld_quantity).toFixed(2)
                                ).toFixed(2)}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#000",
                                  padding: "1%",
                                  borderRight: "2px solid #000",
                                  verticalAlign: "top",
                                  paddingTop: "10px",
                                  width: "100px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}>
                                {parseFloat(
                                  data.fld_GSTamount * data.fld_quantity
                                ).toFixed(2)}
                                <br />
                                <span style={{ fontSize: "13px" }}>
                                  {" "}
                                  @ {data.fld_GSTRate}%
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#000",
                                  verticalAlign: "top",
                                  paddingTop: "10px",
                                  paddingRight: "1%",
                                  width: "100px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}>
                                {parseFloat(data.fld_item_value).toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <table
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRightColor: "#000",
                      borderTop: "hidden",
                      borderRight: "2px solid #000",
                      borderLeft: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                    cellSpacing="0"
                    cellPadding="0">
                    {orderData.length != 0
                      ? orderData.map((data, i) => {
                        if (i === 0) {
                          return (
                            <tbody>
                              <tr
                                style={{
                                  fontSize: "14px",
                                  borderBottom: "2px solid #000",
                                }}>
                                <th
                                  style={{
                                    backgroundColor: "#aeaeaec2",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    fontWeight: "800",
                                    width: "290px",
                                    color: "#000",
                                    borderRight: "2px solid #000",
                                    borderBottom: "1px solid #000",
                                  }}>
                                  You have ordered
                                </th>
                                <th
                                  style={{
                                    paddingTop: "3px",
                                    borderRight: "2px solid #000",
                                    paddingBottom: "3px",
                                    paddingRight: "2%",
                                    textAlign: "right",
                                    fontWeight: "800",
                                    color: "#000",
                                    borderRight: "2px solid #000",
                                    width: "331px",
                                  }}>
                                  Item Total(Inclusive GST)
                                </th>
                                <th
                                  style={{
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    paddingRight: "2%",
                                    textAlign: "right",
                                    fontWeight: "600",
                                    color: "#000",
                                  }}>
                                  {parseFloat(
                                    data.fld_base_total_with_tax
                                  ).toFixed(2)}
                                </th>
                              </tr>
                              <tr
                                style={{
                                  fontSize: "14px",
                                  borderBottom: "2px solid #000",
                                }}>
                                <td
                                  style={{
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    borderRight: "2px solid #000",
                                    width: "290px",
                                    color: "#000",
                                    fontWeight: "600",
                                  }}>
                                  {orderData.length} Products &{" "}
                                  {data.Total_Item_Quantity} Items
                                </td>
                                <td
                                  style={{
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    paddingRight: "2%",
                                    textAlign: "right",
                                    fontWeight: "800",
                                    color: "#000",
                                    borderRight: "2px solid #000",
                                  }}>
                                  Coupon{" "}
                                  {data.fld_coupon_code !== null
                                    ? -data.fld_coupon_code
                                    : ""}
                                </td>
                                <td
                                  style={{
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    paddingRight: "2%",
                                    textAlign: "right",
                                    color: "#000",
                                    fontWeight: "600",
                                  }}>
                                  {data.fld_coupon_discount === null
                                    ? "-"
                                    : parseFloat(
                                      data.fld_coupon_discount.toFixed(2)
                                    )}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontSize: "14px",
                                  borderBottom: "2px solid #000",
                                }}>
                                <td
                                  style={{
                                    backgroundColor: "#aeaeaec2",
                                    paddingTop: "3px",
                                    borderBottom: "1px solid #000",
                                    color: "#000",
                                    paddingBottom: "3px",
                                    fontWeight: "800",
                                    width: "290px",
                                    borderRight: "2px solid #000",
                                  }}>
                                  Amount Chargeable in Words
                                </td>
                                <td
                                  style={{
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    color: "#000",
                                    paddingRight: "2%",
                                    textAlign: "right",
                                    fontWeight: "800",
                                    borderRight: "2px solid #000",
                                  }}>
                                  Taxes
                                </td>
                                <td
                                  style={{
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    color: "#000",
                                    paddingRight: "2%",
                                    textAlign: "right",
                                    fontWeight: "600",
                                  }}>
                                  {parseFloat(data.fld_total_tax).toFixed(2)}
                                </td>
                              </tr>
                              <tr style={{ fontSize: "14px" }}>
                                <td
                                  rowSpan="3"
                                  style={{
                                    paddingLeft: "3px",
                                    textAlign: "left",
                                    border: "none",
                                    color: "#000",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    verticalAlign: "top",
                                    fontWeight: "600",
                                    borderRight: "2px solid #000",
                                  }}>
                                  {amountInWords}
                                </td>
                                <td
                                  style={{
                                    paddingRight: "2%",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    textAlign: "right",
                                    fontWeight: "800",
                                    color: "#000",
                                    borderRight: "2px solid #000",
                                    borderBottom: "2px solid #000",
                                  }}>
                                  Delivery Cost{" "}
                                  {data.Shipping_Charg !== null && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                      }}>
                                      Rs.{" "}
                                      {parseFloat(
                                        data.fld_shipping_base
                                      ).toFixed(2)}{" "}
                                      + 18% TAX
                                    </span>
                                  )}
                                </td>
                                <td
                                  style={{
                                    paddingRight: "2%",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    textAlign: "right",
                                    color: "#000",
                                    fontWeight: "600",
                                    borderBottom: "2px solid #000",
                                  }}>
                                  {data.Shipping_Charg == null ||
                                    data.Shipping_Charg == 0
                                    ? "Free"
                                    : parseFloat(data.Shipping_Charg).toFixed(
                                      2
                                    )}
                                </td>
                              </tr>
                              <tr style={{ fontSize: "14px" }}>
                                <td
                                  style={{
                                    paddingRight: "2%",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    textAlign: "right",
                                    fontWeight: "900",
                                    borderRight: "2px solid #000",
                                    fontSize: "15px",
                                    color: "#000",
                                    borderBottom: "2px solid #000",
                                  }}>
                                  Total Amount INR{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "600",
                                    }}>
                                    (Rounded)
                                  </span>
                                </td>
                                <td
                                  style={{
                                    paddingRight: "2%",
                                    textAlign: "right",
                                    borderBottom: "2px solid #000",
                                    fontWeight: "900",
                                    fontSize: "14px",
                                    color: "#000",
                                  }}>
                                  {parseFloat(
                                    data.fld_total_payable +
                                    data.Shipping_Charg
                                  ).toFixed(2)}
                                </td>
                              </tr>
                              <tr style={{ fontSize: "14px" }}>
                                <td
                                  style={{
                                    paddingTop: "3px",
                                    borderRight: "2px solid #000",
                                    paddingBottom: "3px",
                                    paddingRight: "2%",
                                    textAlign: "right",
                                    fontWeight: "800",
                                    color: "#000",
                                  }}>
                                  Payment Mode
                                </td>
                                <td
                                  style={{
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    paddingRight: "2%",
                                    textAlign: "right",
                                    color: "#000",
                                    fontWeight: "600",
                                  }}>
                                  {data.Payment_Mode}
                                </td>
                              </tr>
                            </tbody>
                          );
                        }
                      })
                      : ""}
                  </table>
                  <table
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRightColor: "#000",
                      borderTop: "hidden",
                      fontFamily: "Lato, sans-serif",
                      borderCollapse: "collapse",
                      marginBottom: "30px",
                      borderRight: "2px solid #000",
                      borderLeft: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                    cellSpacing="1"
                    cellPadding="0">
                    <tbody>
                      <tr
                        style={{
                          borderBottom: "2.5px solid #000",
                          display: discount > 0 ? "block" : "none",
                        }}>
                        <td colSpan="4" style={{ border: "none" }}></td>
                        <td
                          colSpan="6"
                          style={{
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            textAlign: "center",
                            background: "#aeaeaec2",
                            border: "none",
                          }}>
                          <span
                            style={{
                              fontWeight: "800",
                              fontSize: "16px",
                              color: "#000",
                            }}>
                            You have saved Rs.{discount} no value
                          </span>
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "2px solid #000" }}>
                        <td colSpan="4" style={{ border: "none" }}></td>
                        <td
                          colSpan="6"
                          style={{
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            textAlign: "center",
                            border: "none",
                          }}>
                          <span
                            style={{
                              fontWeight: "800",
                              fontSize: "16px",
                              color: "#000",
                            }}>
                            {" "}
                            Have a Question ?{" "}
                          </span>{" "}
                          <br />{" "}
                          <span
                            style={{
                              color: "#000",
                              fontSize: "15px",
                              fontWeight: "600",
                            }}>
                            Call us on {clientPhoneNumber} or Email us at{" "}
                            {clientEmail}
                          </span>
                        </td>
                      </tr>
                      <tr
                        className="success"
                        style={{
                          backgroundColor: "#aeaeaec2",
                          borderBottom: "2.5px solid #000",
                        }}>
                        <td colSpan="4" style={{ border: "none" }}></td>
                        <td
                          colSpan="6"
                          style={{
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            textAlign: "center",
                            background: "#aeaeaec2",
                            color: "#000",
                            fontSize: "20px",
                            fontWeight: "800",
                            border: "none",
                          }}>
                          Shop Online at{" "}
                          <span
                            style={{
                              color: "#000",
                              fontWeight: "800",
                              cursor: "pointer",
                            }}>
                            <a
                              href={clientWesite}
                              target="blank"
                              style={{
                                color: "#000",
                                fontSize: "20px",
                                fontWeight: "800",
                              }}>
                              {clientWesite}
                            </a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
            </Modal>

          </Row>
        </div>
      </section>
    </>
  );
}
