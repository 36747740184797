import React, { useEffect, useState } from "react";
import NavBar from "../nav/nav";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";
import RecentBlogSection from "../recentblogsection/recentblogsection";
import { Skeleton } from "antd";
import Virgo from "../img/zodiac-banner/Virgo.jpg"
import Aries from "../img/zodiac-banner/Aries.jpg"
import Taurus from "../img/zodiac-banner/Taurus.jpg"
import Gemini from "../img/zodiac-banner/Gemini.jpg"
import Cancer from "../img/zodiac-banner/Cancer.jpg"
import Leo from "../img/zodiac-banner/leo.jpg"
import Capricorn from "../img/zodiac-banner/Capricorn.jpg"
import Libra from "../img/zodiac-banner/Libra.jpg"
import Pisces from "../img/zodiac-banner/Pisces.jpg"
import Sagittarius from "../img/zodiac-banner/Sagittarius.jpg"
import Scorpio from "../img/zodiac-banner/Scorpio.jpg"
import GetSeoData from "../API/GetSeoData";
import PostApiCall from "../API/POSTAPI";

const BlogDeatailPage = () => {
  const params = useParams()
  const location = useLocation();
  const [blogData, setBlogData] = useState([]);
  const [blogBanner, setBlogBanner] = useState(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetServiceData();
  }, [params.blogname]);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: `where fld_route = '${params.blogname}'`,
      },
      "GetBlogArticleById"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setBlogData(obj1.data);
          switch (obj1.data[0].fld_title) {
            case "Virgo Zodiac":
              setBlogBanner(Virgo);
              break;
            case "Aries Zodiac":
              setBlogBanner(Aries);
              break;
            case "Taurus Zodiac":
              setBlogBanner(Taurus);
              break;
            case "Gemini Zodiac":
              setBlogBanner(Gemini);
              break;
            case "Cancer Zodiac":
              setBlogBanner(Cancer);
              break;
            case "Leo Zodiac":
              setBlogBanner(Leo);
              break;
            default:
              break;
          }
        }
      })
    );
  };


  return (
    <>
      <GetSeoData type="Blog" id={null} route={params.blogname} />


      <section className="inner-section virgo-page">
        {/* <NavBar /> */}
        <div className="page-title-area" style={{ background: `url(${blogBanner})` }}>
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Horoscope Prediction</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {blogData.length > 0 ?

        <section className="blogsection spacing">
          <div className="container-fluid">
            <div className="row">
              <div className="main-heading mb-4 col-12 mt-lg-4 mt-5 text-center">
                <h2 className="pb-2 ">
                  <span className="px-2">{blogData[0].fld_title}</span>
                </h2>
              </div>
              <div className="col-lg-8">
                <div className="card-body ">
                  <h4 className="card-title"></h4>
                  <div className="virgo-image mb-3">
                    <img
                      src={blogData[0].fld_blogimage}
                      className="  ariesimage img-fluid w-100"
                      alt="Astrology Services"
                    ></img>
                  </div>

                  <p className="card-text text-dark">
                    {parse(blogData[0].fld_short_description)}
                  </p>
                  <p className="card-text text-dark">{parse(blogData[0].fld_content)}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <RecentBlogSection />

              </div>
            </div>
          </div>
        </section> : <section className="blogsection">
          <div className="container">
            <div className="row">
              <div className="main-heading  mb-0 col-12 mt-lg-5 mt-5 text-center">
                <h2 className="pb-2 ">
                  <span className="px-2"><Skeleton.Input /></span>{" "}
                </h2>
              </div>
              <div className="col-lg-8">
                <div className="card-body ">
                  <h4 className="card-title mt-3"></h4>
                  <div className="virgo-image">
                    <Skeleton.Image
                      className="  ariesimage img-fluid w-100"
                      alt="Astrology Services"
                    />
                  </div>

                  <p className="card-text text-dark">
                    <Skeleton.Input />
                  </p>
                  <p className="card-text text-dark"><Skeleton.Input /></p>
                </div>
              </div>
              <div className="col-lg-4">
                <RecentBlogSection />

              </div>
            </div>
          </div>
        </section>}
    </>
  );
};

export default BlogDeatailPage;
