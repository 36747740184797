import { Skeleton } from 'antd';
import Parser from 'html-react-parser';
import { useEffect, useState } from "react";
import { store } from '../Helper/Store';
import PostApiCall from '../API/POSTAPI';
import "./StaticPage.css";
import GetSeoData from '../API/GetSeoData';
export default function StaticPage() {
  const [staticPages, setStaticPages] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // document.title = pagename; // can be used later
    PostApiCall.postRequest(
      {
        whereclause: `where fld_route = '${window.location.pathname}'`,
      },
      "GetStaticPages"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  let pagesData = window.location.pathname.split("/")[1].replace(/-/g, " ");

  return (
    <>
      <section className="inner-section static-page">
        {/* <NavBar /> */}
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Horoscope Prediction</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <GetSeoData type={capitalizeWords(pagesData)} id={null} />
      <section className="spacing py-4 blank-section static-pages">
        <div className="container-fluid">
          <div className="row">
            {staticPages.length > 0 ?
              <div className="col-12">
                <h1 className="text-center main-heading mb-0">
                  {staticPages[0]?.fld_page_heading}
                </h1>
                <p>
                  {Parser(
                    ("<p>" + staticPages[0]?.fld_content + "</p>")
                      .replace(/font-family/g, "")
                      .replace(/<br\/?>/gi, " ")
                  )}
                </p>
              </div>
              :
              <Skeleton className="mt-4" />}
          </div>
        </div>
      </section>
    </>
  );
}
