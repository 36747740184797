import React from "react";
import NavBar from "../nav/nav";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./media.css";
import wcImg from "../img/wcu/bg.png";
import w1 from "../img/wcu/astro.png";
import w2 from "../img/wcu/hq.png";
import w3 from "../img/wcu/trust.png";
import w4 from "../img/wcu/exp.png";
import w5 from "../img/wcu/pra.png";
import w6 from "../img/wcu/hol.png";
import { Helmet } from "react-helmet";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import GetSeoData from "../API/GetSeoData";

function MediaPage() {
    return (
        <>
            <GetSeoData type="podcast" id={null} />
            {/* <Container className="mt-5 pt-3 why-choose-section">
    <Row>
        <Col xs={12} className="section-head">
          <h4><span>Why Choose</span> Us?</h4>
          <p>Choose Blessings Astrology for Your Spiritual Journey: Unmatched Expertise, Compassionate Guidance, and Personalized Support!</p>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_one"></span>
            <h6>Comprehensive Astrological Services</h6>
            <p> Blessings Astrology offers a wide range of astrology-related services to help you make better decisions in your life.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_two"></span>
            <h6>High-Quality Astrological Forecasts and Advice</h6>
            <p>The platform has a reputation for providing high-quality astrological forecasts and advice, which can help you make informed decisions about your life.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_three"></span>
            <h6>Expertise and Experience</h6>
            <p> Dr. Surabhi Bhatnagar's expertise and experience in the field of astrology makes clients comfortable and solutions are given in a practical manner.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_four"></span>
            <h6>Trustworthy and Confidential</h6>
            <p>Blessings Astrology is committed to maintaining the highest standards of confidentiality and trust, ensuring that your personal information is kept safe and secure.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_five"></span>
            <h6>Practical Solutions</h6>
            <p>Blessings Astrology provides practical solutions that are tailored to your individual needs and challenges, taking into account the demands of modern life.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_six"></span>
            <h6>Holistic Approach</h6>
            <p> Blessings Astrology takes a holistic approach to astrology, considering all aspects of your life to help you achieve balance, harmony, and success in all areas of your life.</p>
          </div>
        </Col>
      </Row>
    </Container> */}
            <section className="inner-section horoscope-page media-page">
                {/* <NavBar /> */}
                <div className="page-title-area media-banner">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <section className="card-section pb-4 spacing ">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="main-heading text-center mb-0 col-12">
                                <h2 className="pb-2 py-3">Podcast</h2>
                                <h5>Welcome to the Podcast of Blessings Astrology!</h5>
                                <p>We're excited to share our journey and presence across various media platforms with you. Catch us on YouTube, Instagram, and Facebook, where we regularly share insightful content, tips, and updates on astrology, spirituality, and holistic wellness. Our engaging videos, inspiring posts, and live sessions aim to empower and enlighten our audience on their spiritual journey.</p>
                                <p>In addition to our social media presence, we've had the honor of being featured in interviews with prestigious publications like Dainik Jagran and many more. These interactions have provided us with opportunities to share our expertise and insights with a wider audience, further solidifying our commitment to spreading knowledge and positivity.</p>
                                <p>Join us on our social media channels and stay connected for more enriching content, live sessions, and updates. Follow, like, and subscribe to Blessings Astrology to embark on a transformative journey of self-discovery and enlightenment.





                                </p>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe width="853" height="480" src="https://www.youtube.com/embed/yZoG91kb4k0" title="Astrology Uncovered: Fascinating Facts &amp; Your Burning Questions - Part 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe width="853" height="480" src="https://www.youtube.com/embed/8FfUs7X3TfE" title="What&#39;s Hiding In Your NUMBERS Dr Surbhi Bhatnagar Reveals All?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe width="853" height="480" src="https://www.youtube.com/embed/qYx01AW6PA8" title="KUNDALI के रहस्य | Astrology and Numerology Secrets with Dr Surabhi in Master Ji Ki Baithak" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe width="853" height="480" src="https://www.youtube.com/embed/DBuMRA82zvY" title="Vastu Of Our House And Workplace | Vastu Expert And Astrologist" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe src="https://www.youtube.com/embed/fWOiE87Ov7g" title="वास्तु ज़िंदगी की कायापलट कर सकता है 😲🔥| @blessingsastrologybydr.sur7267  | TVT Podcast Ep - 71" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe src="https://www.youtube.com/embed/lKix1-dMr7Y" title="वास्तु ज़िंदगी की कायापलट कर सकता है 😲🔥| @blessingsastrologybydr.sur7267  | TVT Podcast Ep - 71" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe width="914" height="514" src="https://www.youtube.com/embed/LVMlTlP8jKs" title="कलयुग का राक्षस कहां पल रहा है❓😱 | TVT Podcast Clips" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe width="914" height="514" src="https://www.youtube.com/embed/9FF1YGwntPs" title="2024 Horoscope, शनि देव का साल आ गया🔥☝️ | @blessingsastrologybydr.sur7267 | TVT Podcast Ep - 79" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe width="914" height="514" src="https://www.youtube.com/embed/QwwOIsKgH3s" title="कैसे इंसान बनता जा रहा है दानव? | Dr. Surabhi Bhatnagar | TVT Podcast Ep - 83" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe width="914" height="514" src="https://www.youtube.com/embed/qT4npKRcd-I" title="PM Modi LIVE: पीएम मोदी का संबोधन, मंदिर परिसर में मौजूद रामभक्त | Ram Mandir Pran Pratishtha" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6 media-card mb-4">
                                <iframe width="914" height="514" src="https://www.youtube.com/embed/y72_iEmxi-k" title="Ram Mandir Pran Pratishtha LIVE: देश में एक ही नाम, अयोध्या में आ गए राम |  | PM Modi Live" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>

                            <div className="main-heading text-center mb-0 col-12 mb-2">
                                <h2 className="">Youtube Shorts</h2>
                                {/* <h5>Welcome to the Media Page of Blessings Astrology!</h5> */}
                            </div>

                            {/* <div className="col-md-3 mb-4">
                                <iframe width="338" height="402" src="https://www.youtube.com/embed/4BsTeZhn_ks" title="मेरे घर में यमराज आए थे 😲🙏  #TVTPodcast #Podcast #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-3 mb-4">
                                <iframe width="338" height="402" src="https://www.youtube.com/embed/cZDvsnNYil8" title="शमशान की जमीन पर घर बनाया है 😱😨  #TVTPodcast #Podcast #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-3 mb-4">
                                <iframe width="338" height="402" src="https://www.youtube.com/embed/d-MjFCOfRqM" title="बिना शरीर भगवान भी क्या करते❓❓#tvtpodcast #truevaluetalks #tvt #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-3 mb-4">
                                <iframe width="338" height="402" src="https://www.youtube.com/embed/sbhhBQ-AnTQ" title="क्या शनि देंगे आपका साथ या😱😱 #tvtpodcast #truevaluetalks #tvt #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-3 mb-4">
                                <iframe width="338" height="402" src="https://www.youtube.com/embed/sbhhBQ-AnTQ" title="क्या शनि देंगे आपका साथ या😱😱 #tvtpodcast #truevaluetalks #tvt #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div> */}
                            <Swiper
                                spaceBetween={30}
                                loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                }}
                                autoplay={{
                                    delay: 5000,
                                    pauseOnMouseEnter: true,
                                    disableOnInteraction: false,

                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper testimonial-swiper pt-2"
                            >



                                <SwiperSlide>

                                    <div className="">
                                        <iframe width="338" height="402" src="https://www.youtube.com/embed/4BsTeZhn_ks" title="मेरे घर में यमराज आए थे 😲🙏  #TVTPodcast #Podcast #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="">
                                        <iframe width="338" height="402" src="https://www.youtube.com/embed/cZDvsnNYil8" title="शमशान की जमीन पर घर बनाया है 😱😨  #TVTPodcast #Podcast #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="">
                                        <iframe width="338" height="402" src="https://www.youtube.com/embed/d-MjFCOfRqM" title="बिना शरीर भगवान भी क्या करते❓❓#tvtpodcast #truevaluetalks #tvt #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="">
                                        <iframe width="338" height="402" src="https://www.youtube.com/embed/sbhhBQ-AnTQ" title="क्या शनि देंगे आपका साथ या😱😱 #tvtpodcast #truevaluetalks #tvt #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="">
                                        <iframe width="338" height="402" src="https://www.youtube.com/embed/sbhhBQ-AnTQ" title="क्या शनि देंगे आपका साथ या😱😱 #tvtpodcast #truevaluetalks #tvt #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </SwiperSlide>





                            </Swiper>

                            <div className="main-heading text-center mb-0 col-12">
                                <h2 className="">Instagram</h2>
                                {/* <h5>Welcome to the Media Page of Blessings Astrology!</h5> */}
                            </div>

                            {/* <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C1RvIYpxqW_/embed/" frameborder="0"></iframe>

                            </div>
                            <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C1Zm1r1xbft/embed/" frameborder="0"></iframe>

                            </div>
                            <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C115auQx0So/embed/" frameborder="0"></iframe>

                            </div>
                            <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C2AM1pmSEsr/embed/" frameborder="0"></iframe>

                            </div>
                            <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C2STGrmyDsv/embed/" frameborder="0"></iframe>

                            </div>
                            <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C2chz7JIW7g/embed/" frameborder="0"></iframe>

                            </div>
                            <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C2hwsI0vH0r/embed/" frameborder="0"></iframe>

                            </div>
                            <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C2chz7JIW7g/embed/" frameborder="0"></iframe>

                            </div>
                            <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C2uqwC7y7wh/embed/" frameborder="0"></iframe>

                            </div>
                            <div className="">
                                <iframe width="320" height="550" src="https://www.instagram.com/reel/C2-BS78SCw1/embed/" frameborder="0"></iframe>

                            </div> */}

                            <Swiper
                                spaceBetween={30}
                                loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                }}
                                autoplay={{
                                    delay: 5000,
                                    pauseOnMouseEnter: true,
                                    disableOnInteraction: false,

                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper testimonial-swiper pt-2"
                            >



                                <SwiperSlide>

                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C1RvIYpxqW_/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C1Zm1r1xbft/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C115auQx0So/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C2AM1pmSEsr/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C2STGrmyDsv/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C2chz7JIW7g/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>
                                {/* <SwiperSlide>
                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C2hwsI0vH0r/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide> */}
                                <SwiperSlide>
                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C2chz7JIW7g/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>
                                {/* <SwiperSlide>
                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C2uqwC7y7wh/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide> */}
                                <SwiperSlide>
                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/C2-BS78SCw1/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/DECnBU1ySlG/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>
                                {/* <SwiperSlide>
                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/DDtze3pS08h/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide> */}
                                <SwiperSlide>
                                    <div className="">
                                        <iframe width="320" height="550" src="https://www.instagram.com/reel/DDB2TlrvURp/embed/" frameborder="0"></iframe>
                                    </div>
                                </SwiperSlide>





                            </Swiper>


                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}
export default MediaPage;