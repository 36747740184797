import React from "react";
import { Link } from "react-router-dom";
import PageNotFoundIcon from "../img/404.png";
const PageNotFound = () => {
  return (
    <>
      <div>
        <div className="container mt-5 pt-5 not-found-page">
          <div className="row marginbtm-240">
            <div className="col-md-12">
              <div className="four-o-four mt-4 mb-5">
                <div className="d-flex justify-content-center">
                  <img
                    src={PageNotFoundIcon}
                    className="paymentFail img-fluid h-50"
                    alt="payment failed"
                    height="500px"
                    width="500px"
                  ></img>
                </div>
                <div
                  className="center-block mt-3"
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p>The page you are looking for is not found.</p>
                  {/* <h1>Something's missing.</h1> */}
                  <div className="d-flex justify-content-center">
                    <Link to="/" className="read-more">
                      Go To Homepage
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageNotFound;
