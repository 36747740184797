import "./HomeGallerySection.css";

import { Helmet } from "react-helmet";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Skeleton } from "antd";
import imageone from "../img/gallery/3.jpg";
import imagetwo from "../img/gallery/33.jpg";
import imagethree from "../img/gallery/30.jpg";
import imagefour from "../img/gallery/43.jpeg";
// import imagefive from "../img/gallery/26.jpg";
import imagesix from "../img/gallery/17.jpg";
import imageseven from "../img/gallery/24.jpg";
import bookone from "../img/gallery/book-one.jpeg"
import booktwo from "../img/gallery/book-two.jpeg"
import bookthree from "../img/gallery/book-three.jpeg"
import bookfour from "../img/gallery/book-four.jpeg"



export default function HomeGallerySection() {
  return (
    <>

      <section className="inner-section spacing products-page mt-4 main-product-page">
        <div className="container-fluid pb-3">
          <div className="row">
            <div className="text-center col-12 text-center t-head mb-2 main-heading">
              <h2 className="mb-0">Journey of Excellence</h2>
              <p>Milestones and Achievements Along the Way





              </p>
            </div>
            <div className="col-12">
              <Swiper
                slidesPerView={2}
                spaceBetween={15}
                // loop={true}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                  },
                }}
                autoplay={{
                  delay: 1000,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper testimonial-swiper home-gallery-swiper pt-2">
                <SwiperSlide>
                  <div>
                    <img src={bookone} className="img-fluid"></img>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img src={booktwo} className="img-fluid"></img>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img src={bookthree} className="img-fluid"></img>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img src={bookfour} className="img-fluid"></img>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img src={imageone} className="img-fluid"></img>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img src={imagetwo} className="img-fluid"></img>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img src={imagethree} className="img-fluid"></img>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img src={imagefour} className="img-fluid"></img>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                                <div>
                                    <img src={imagefive} className="img-fluid">
                                    </img>
                                </div>
                            </SwiperSlide> */}
                <SwiperSlide>
                  <div>
                    <img src={imagesix} className="img-fluid"></img>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img src={imageseven} className="img-fluid"></img>
                  </div>
                </SwiperSlide>

              </Swiper>
            </div>
            <div class="read-more col-12 text-center mt-3">
              <a href="/journey-of-excellence"> View All</a>
            </div>
          </div>

          {/* <div className="btn-box text-center">
            <a href="/" className="button button-2">
              View All
            </a>
          </div> */}
        </div>
      </section>
    </>
  );
}
