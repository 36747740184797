import { DatePicker, Input, Select, Space, TimePicker, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from 'dayjs';
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { TfiReload } from "react-icons/tfi";
import { store } from "../Helper/Store";
import "./appointmentpage.css";
import PostApiCall from ".././API/POSTAPI";
import Form from "react-bootstrap/Form";
import moment from "moment";
import CalendarTime from "./CalendarTime";
import { Modal } from "react-bootstrap";
import GetApiCall from "../API/GETAPI";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function Appointmentform({ serviceName, showForm, closeModal }) {
  useEffect(() => {
    generateRandomCaptcha();
    // GetAllBookedSlot();
  }, []);
  // const GetAllBookedSlot = () => {
  //   PostApiCall.postRequest(
  //     {
  //       catCount: "*",
  //       whereClause: "",
  //     },
  //     "GetAllAppointments"
  //   ).then((results) =>
  //     results.json().then((obj) => {
  //       if (results.status === 200 || results.status === 201) {
  //         setBookedSlots(obj.data);
  //       } else {
  //         Notiflix.Notify.failure("Something went wrong!");
  //       }
  //     })
  //   );
  // };

  // const [bookedSlots, setBookedSlots] = useState([]);

  const [captcha, setCaptcha] = useState("");
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [mobile, setMobile] = useState("");
  const [dialcode, setDialcode] = useState("");
  const [email, setEmail] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthTime, setBirthTime] = useState("");
  const [showDOB, setShowDOB] = useState(false);
  const [showTOB, setShowTOB] = useState(false);
  const [showPOB, setShowPOB] = useState(false);

  const [message, setMessage] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [currentStatus, setCurrentStatus] = useState("New");
  const [serviceData, setServiceData] = useState([]);
  const [timeInterval, setTimeInterval] = useState("")
  const [vastuArea, setVastuArea] = useState("")


  // image upload
  const [fileList, setFileList] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [urlData, setUrlData] = useState([]);
  useEffect(() => {
    GetServiceData();
  }, []);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: "",
      },
      "Services"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setServiceData(
            obj1.data.filter((data) => data.fld_status === "Active")
          );
        }
      })
    );
  };

  // Calendar

  const [showSlot, setShowSlot] = useState(false);

  function disabledDate(current) {
    if (current && current.isBefore(moment(), "day")) {
      return true;
    }

    // Count occurrences of each date
    // const dateCounts = bookedSlots.reduce((acc, item) => {
    //   acc[item.fld_date] = (acc[item.fld_date] || 0) + 1;
    //   return acc;
    // }, {});

    // Filter dates that occur 7 times
    // const filteredDates = Object.keys(dateCounts).filter(
    //   (date) => dateCounts[date] === 7
    // );

    // Check if the current date is in the filtered dates
    // return filteredDates.includes(current.format("YYYY/MM/DD"));
  }

  const handleChangeDate = (value) => {
    setSelectedDate(value.format("YYYY/MM/DD"));
    setShowSlot(true);
  };

  // dialcode phone number
  const handleCountryChange = (value, country) => {
    setMobile(country.inputValue)
    setDialcode("+" + country.country.dialCode)
  };

  // Booking Form

  const handleChange = (value) => {
    setSelectedService(value);
    setName("");
    setGender("");
    setBirthTime("")
    setBirthDate("")
    setVastuArea("")
    setMaritalStatus("");
    setBirthPlace("");
    setMobile("");
    setEmail("");
    setSelectedDate("");
    setSelectedTime("");
    setMessage("");
    setUrlData([])
    switch (value) {
      case "Astrology":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Vastu Shastra":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Handwriting and Signature Analysis":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Numerology":
        setShowDOB(true)
        setShowPOB(false);
        setShowTOB(false);
        break;
      case "Palmistry":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Tarot":
        setShowDOB(false)
        setShowPOB(false);
        setShowTOB(false);
        break;
      case "Reiki Healing":
        setShowDOB(false)
        setShowPOB(false);
        setShowTOB(false);
        break;
      default:
        setShowDOB(false)
        setShowPOB(false);
        setShowTOB(false);
    }
  };

  const handleRegexValidation = (email, phone) => {
    let result;
    if (email) {
      result = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    } else {
      result = /^\d{10}$/.test(phone);
    }
    return result;
  };
  const generateRandomCaptcha = () => {
    const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
    setCaptcha(newCaptcha);
  };
  function isCaptchaValid(enteredCaptcha) {
    return captcha == enteredCaptcha;
  }
  function handleValidateForm() {
    if (selectedService === "") {
      Notiflix.Notify.failure("Service is required", { zindex: 99999 });
      return;
    }
    if (name === "") {
      Notiflix.Notify.failure("Please enter your name", { zindex: 99999 });
      return;
    }
    if (gender === "") {
      Notiflix.Notify.failure("Please select your gender", { zindex: 99999 });
      return;
    }
    if (maritalStatus === "") {
      Notiflix.Notify.failure("Please select your martial status", { zindex: 99999 });
      return;
    }
    if (showDOB === true && birthDate === "") {
      Notiflix.Notify.failure("Please select your birth date", { zindex: 99999 });
      return;
    }
    if (showTOB === true && birthTime === "") {
      Notiflix.Notify.failure("Please select your birth time", { zindex: 99999 });
      return;
    }
    if (showPOB === true && birthPlace === "") {
      Notiflix.Notify.failure("Please enter your birth place", { zindex: 99999 });
      return;
    }
    if (selectedService === "Vastu Shastra" && vastuArea === "") {
      Notiflix.Notify.failure("Vastu area is required", { zindex: 99999 });
      return;
    }
    if (mobile === "" || mobile.replace(/\s+/g, '') === dialcode.replace(/\s+/g, '')) {
      Notiflix.Notify.failure("Please enter your phone number", { zindex: 99999 });
      return;
    }
    // else if (!handleRegexValidation("", mobile)) {
    //   Notiflix.Notify.failure("Invalid phone number", { zindex: 99999 });
    //   return;
    // }
    if (email == "") {
      Notiflix.Notify.failure("Please enter your email address", { zindex: 99999 });
      return;
    } else if (!handleRegexValidation(email, "")) {
      Notiflix.Notify.failure("Invalid email address", { zindex: 99999 });
      return;
    }
    if (selectedService === "Palmistry" && urlData.length !== 2) {
      Notiflix.Notify.failure("Hand images are required", { zindex: 99999 });
      return;
    }
    if (selectedService === "Handwriting and Signature Analysis" && urlData.length !== 1) {
      Notiflix.Notify.failure("Handwritting or signature image is required", { zindex: 99999 });
      return;
    }
    if (selectedDate === "") {
      Notiflix.Notify.failure("Please select appointment date", { zindex: 99999 });
      return;
    }
    if (timeInterval === "") {
      Notiflix.Notify.failure("Please select appointment time duration", { zindex: 99999 });
      return;
    }
    if (selectedTime === "") {
      Notiflix.Notify.failure("Please select appointment time", { zindex: 99999 });
      return;
    }
    // if (message === "") {
    //   Notiflix.Notify.failure("Message is required", { zindex: 99999 });
    //   return;
    // }
    if (!isCaptchaValid(enteredCaptcha)) {
      Notiflix.Notify.failure("Invalid Captcha", { zindex: 99999 });
      return;
    }
    onSubmit();
  }

  function clearFormData() {
    setName("");
    setGender("");
    setMaritalStatus("");
    setBirthPlace("");
    setMobile("");
    setEmail("");
    setSelectedDate("");
    setSelectedTime("");
    setMessage("");
    setSelectedService("");
  }

  function onSubmit() {
    Notiflix.Loading.dots("Please wait...", { zindex: 99999 });
    PostApiCall.postRequest(
      {
        appointment_id: null,
        name: name,
        gender: gender,
        maritalStatus: maritalStatus,
        tob: birthTime !== "" ? birthTime : null,
        dob: birthDate !== "" ? birthDate : null,
        birthPlace: birthPlace,
        mobile: mobile,
        email: email,
        area: vastuArea !== "" ? vastuArea : null,
        time: selectedTime,
        slotduration: timeInterval,
        date: selectedDate,
        service: selectedService,
        image1: urlData.length > 0 ? selectedService === "Handwriting and Signature Analysis" || selectedService === "Palmistry" ? urlData[0].imageUrl : null : null,
        image2: urlData.length == 2 && selectedService === "Palmistry" ? urlData[1].imageUrl : null,
        message: message,
        status: currentStatus,
      },
      "BookAppointment"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          clearFormData();
          Notiflix.Loading.remove();
          closeModal();
          Notiflix.Notify.success("Form Submitted Successfully.", {
            zindex: 99999,
          });
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong!", { zindex: 99999 });
        }
      })
    );
  }

  // Handle Image

  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  // };


  const handleChangeImage = async ({ file, fileList }) => {
    const updatedFileList = [...fileList];
    const index = updatedFileList.findIndex((item) => item.uid === file.uid);
    if (index !== -1) {
      updatedFileList[index] = { ...file, status: "done" };
      setFileList(updatedFileList);
    }
    if (file.status === "done") {
      updatedFileList[index] = { ...file, status: "done" };
      setFileList(updatedFileList);
    } else if (file.status === "error") {
      updatedFileList[index] = { ...file, status: "error" };
      setFileList(updatedFileList);
    } else if (file.status === "removed") {
      setFileList(updatedFileList.filter((item) => item.uid !== file.uid));
      setUrlData(urlData.filter((item) => item.uid !== file.uid))
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const onaction = async (dat) => {
    const formData = new FormData();
    formData.append("filename", dat.file.name.replace(/ /g, "-"));
    formData.append("foldername", "ProductReviewImages");
    formData.append("file", dat.file);
    await axios
      .post("https://api.bechofy.in/AddImage", formData, {
        headers: {
          Schema: "Blessings",
        },
      })
      .then((res) => {
        setUrlData([...urlData, { uid: dat.file.uid, imageUrl: res.data.image }]);
      })
      .catch((err) => {
        if (err.response) {
          console.log("Error Response:", err.response.data);
          console.log("Error Status:", err.response.status);
          console.log("Error Headers:", err.response.headers);
        } else if (err.request) {
          console.log("Error Request:", err.request);
        } else {
          console.log("Error Message:", err.message);
        }
        console.log("Error Config:", err.config);
      });
  };

  return (
    <>
      <Modal
        show={showForm}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        size="xl"
        className="appointment-form-modal"
      >
        <Modal.Header closeButton className="pb-0"></Modal.Header>
        <Modal.Body className="p-lg-0">
          {/* <Appointmentform serviceName={serviceTitle} /> */}

          <section className="spacing">
            <h2 className=" mt-3 text-center"> Book An Appointment</h2>
            <p className="text-center">
              Schedule Your Personalized Consultation with Ease{" "}
            </p>
            <div className="container-fluid mb-3">
              <div className="row contact-form mt-3 select-service-box">
                <div className="col-lg-12 mb-4 select-service-option">
                  <label for="service">Preferred Service <span className="text-danger">*</span></label>
                  <Form.Select
                    id="service"
                    aria-label="Default select example"
                    onChange={(e) => handleChange(e.target.value)}
                    defaultValue={selectedService}
                  >
                    <option value disabled selected>
                      Select Service
                    </option>
                    {serviceData.map((items, index) => (
                      <option value={items.fld_service_name} key={index}>
                        {items.fld_service_name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="name">Name <span className="text-danger">*</span></label>
                  <Input
                    id="name"
                    // placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="gender">Gender <span className="text-danger">*</span></label>
                  <Form.Select
                    id="gender"
                    // aria-label="Default select example"
                    onChange={(e) => setGender(e.target.value)}
                    defaultValue={gender}
                    disabled={selectedService !== "" ? false : true}
                  >
                    <option disabled value="">
                      Select Gender
                    </option>
                    <option value="Male">
                      Male
                    </option>
                    <option value="Female">
                      Female
                    </option>
                    <option value="Other">
                      Other
                    </option>

                  </Form.Select>
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="maritalStatus">Marital Status <span className="text-danger">*</span></label>
                  <Form.Select
                    id="maritalStatus"
                    // aria-label="Marital Status"
                    value={maritalStatus}
                    // placeholder="Marital Status"
                    onChange={(e) => setMaritalStatus(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  >
                    <option value="" disabled>
                      Marital Status
                    </option>
                    <option value="Married">
                      Married
                    </option>
                    <option value="Unmarried">
                      Unmarried
                    </option>

                  </Form.Select>
                </div>
                {showDOB === true
                  &&
                  <div className="col-lg-4 mb-4 date-picker-appionment">
                    <label for="dob">Date Of Birth <span className="text-danger">*</span></label>

                    <DatePicker
                      id="dob"
                      className="ant-input css-dev-only-do-not-override-1gwfwyx ant-input-outlined date-picker"
                      format="YYYY/MM/DD"
                      onChange={(value) => setBirthDate(value.format("YYYY/MM/DD"))}
                      // placeholder="Date of birth"
                      disabled={selectedService !== "" ? false : true}
                      showNow={false}
                      disabledDate={(current) => current && current >= moment().endOf('day')}

                    />
                  </div>
                }
                {showTOB === true &&
                  <div className="col-lg-4 mb-4 date-picker-appionment">
                    <label for="tob">Time Of Birth <span className="text-danger">*</span></label>
                    <TimePicker id="tob" use12Hours onChange={(value) => setBirthTime(value?.format("HH:mm A"))} showSecond={false} showNow={false} />
                  </div>
                }
                {showPOB === true &&
                  <div className="col-lg-4 mb-4">
                    <label for="pob">Place Of Birth <span className="text-danger">*</span></label>
                    <Input
                      id="pob"
                      // placeholder="Place Of Birth"
                      value={birthPlace}
                      onChange={(e) => setBirthPlace(e.target.value)}
                    />
                  </div>
                }
                {selectedService === "Vastu Shastra" &&
                  <div className="col-lg-4 mb-4">
                    <label for="vastuarea">Vastu Area <span className="text-danger">*</span></label>
                    <Form.Select
                      id="vastuarea"
                      aria-label="Area"
                      value={vastuArea}
                      // placeholder="Area"
                      onChange={(e) => setVastuArea(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Area
                      </option>
                      <option value="Home">
                        Home
                      </option>
                      <option value="Industrial">
                        Industrial
                      </option>
                      <option value="Office / Shop">
                        Office / Shop
                      </option>

                    </Form.Select>
                  </div>
                }


                <div className="col-lg-4 mb-4">
                  <label for="phone">Phone Number <span className="text-danger">*</span></label>
                  <PhoneInput
                    id="phone"
                    defaultCountry="in"
                    value={mobile}
                    disabled={selectedService !== "" ? false : true}
                    forceDialCode={true}
                    onChange={handleCountryChange}
                  />
                  {/* <Input
                    id="phone"
                    placeholder="Phone no"
                    value={mobile}
                    disabled={selectedService !== "" ? false : true}
                    onChange={(mobile) => {
                      if (mobile.target.value.length <= 10) {
                        setMobile(mobile.target.value);
                      }
                    }}
                  /> */}
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="email">Email Address <span className="text-danger">*</span></label>
                  <Input
                    id="email"
                    // placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>

                <div className="col-lg-4 mb-4 date-picker-appionment">
                  <label for="appointmentDate">Preferred Date <span className="text-danger">*</span></label>

                  <DatePicker
                    id="appointmentDate"
                    className="ant-input css-dev-only-do-not-override-1gwfwyx ant-input-outlined date-picker"
                    format="YYYY/MM/DD"
                    onChange={handleChangeDate}
                    disabledDate={disabledDate}
                    // placeholder="Preferred Date"
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>
                <div className="col-lg-4 mb-4 date-picker-appionment">
                  <label for="interval">Time <span className="text-danger">*</span></label>
                  <Form.Select
                    id="interval"
                    // aria-label="Interval"
                    value={timeInterval}
                    // placeholder="Interval"
                    onChange={(e) => setTimeInterval(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  >
                    <option value="" disabled>
                      Time
                    </option>
                    <option value="half an hour">
                      Half An Hour
                    </option>
                    <option value="one hour">
                      One Hour
                    </option>

                  </Form.Select>
                </div>
                <div className="col-lg-4 mb-4 date-picker-appionment">
                  <label for="slot">Time Slot <span className="text-danger">*</span></label>
                  <Form.Select
                    id="slot"
                    // aria-label="Interval"
                    value={selectedTime}
                    // placeholder="Interval"
                    onChange={(e) => setSelectedTime(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  >
                    <option value="" disabled>
                      Time Slots
                    </option>
                    <option value="09:00 am - 12:00 pm">
                      09:00 am - 12:00 pm
                    </option>
                    <option value="12:00 pm - 03:00 pm">
                      12:00 pm - 03:00 pm
                    </option>
                    <option value="03:00 pm - 06:00 pm">
                      03:00 pm - 06:00 pm
                    </option>

                  </Form.Select>
                  {/* <CalendarTime
                    allbookedSlots={bookedSlots}
                    selectedDate={selectedDate}
                    closeSlot={toggleSlot}
                  /> */}
                </div>
                {selectedService === "Handwriting and Signature Analysis" &&
                  <div>
                    <label for="imageUpload" className="mb-2">Handwriting or Signature Image <span className="text-danger">*</span></label>
                    <Upload
                      id="imageUpload"
                      customRequest={onaction}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={() => { }}
                      // onPreview={handlePreview}
                      onChange={handleChangeImage}
                    >
                      {/* at most 8 images can be selected  */}
                      {/* {uploadButton} */}
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </div>}
                {selectedService === "Palmistry" &&
                  <div>
                    <label className="mb-2" for="imageUpload2">Front Hand and Side Hand Image <span className="text-danger">*</span></label>
                    <Upload
                      id="imageUpload2"
                      customRequest={onaction}
                      listType="picture-card"
                      fileList={fileList}
                      // onPreview={handlePreview}
                      onPreview={() => { }}
                      onChange={handleChangeImage}
                    >
                      {/* at most 8 images can be selected  */}
                      {/* {uploadButton} */}
                      {fileList.length >= 2 ? null : uploadButton}
                    </Upload>
                  </div>}


                <div className="col-12 mb-4 mt-3">
                  <label for="message" className="mb-2">Message</label>
                  <TextArea
                    id="message"
                    rows={5}
                    // placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>
                <div className="form-group col-lg-4 mb-4 pb-0">
                  <div className="captcha-box">
                    <input
                      type="text"
                      value={captcha}
                      disabled
                      className="form-control"
                    />
                    <div
                      className="captcha"
                      onClick={() => generateRandomCaptcha()}
                    >
                      <TfiReload />
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-6 mb-4">
                  <input
                    type="text"
                    value={enteredCaptcha}
                    onChange={(e) =>
                      setEnteredCaptcha(e.target.value.toUpperCase())
                    }
                    className="form-control"
                    placeholder="Enter Captcha *"
                  />
                </div>
                <div className="col-12 mb-3">
                  <div className="read-more">
                    <a
                      className="theme-btn border-0"
                      onClick={handleValidateForm}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}
