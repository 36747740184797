import React, { useEffect, useState } from "react";
import "./TopBar.css";
import PostApiCall from "../API/POSTAPI";
export default function TopBar() {
  const [keyStrings, setKeyStrings] = useState([]);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetKeyStrings"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setKeyStrings(
            obj1.data.filter((dat) => dat.FLD_Key_Name === "Main-top")
          );
        }
      })
    );
  }, []);
  return (
    <>
      {keyStrings && (
        <div className="container-fluid top-bar">
          <div className="row">
            <div className="col-12 text-center py-2">
              <p className="mb-0 d-none d-lg-block">
                {keyStrings[0]?.FLD_Key_Value}
              </p>
              {/* eslint-disable jsx-a11y/no-distracting-elements */}
              <marquee className="d-block d-lg-none">
                {keyStrings[0]?.FLD_Key_Value}
              </marquee>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
