import { Skeleton } from "antd";
import "./parallax.css";
export default function parallax({ parallaxData, height }) {
  return (
    <section className=" spacing mobile-spacing">
      {parallaxData.length > 0 ? (
        <div
          className="container-fluid parallax-section mt-lg-4 mt-3"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${
              parallaxData[0]?.image.split("$")[0]?.split("#")[0]
            })`,
          }}>
          <div className="row">
            <div className="col-lg-8 mx-auto">
              {/* <div className="text-center col-12 text-center t-head mb-4 main-heading">
                        <h4>Parallax</h4>
                        <p>Lorem Lorem Lorem Lorem Lorem Lorem Lorem</p>
                        
                        </div> */}
              <div className="parallax-content text-center main-heading">
                <h1>{parallaxData[0]?.heading}</h1>
                <p className="text-white fs-4">{parallaxData[0]?.title}</p>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-3">
                <div className="view-more">
                  <a href="/horoscope-prediction">Reveal My Horoscope</a>
                </div>
                {/* <div className="shop-now m-2">
                            <a href="" >Book Now
                            
                            </a>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid bg-light parallax-section mt-lg-4 mt-3">
          <div className="row">
            <div className="col-12">
              <Skeleton.Image
                className="img-fluid w-100 h-auto"
                alt="Blessing Products"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
