import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { MdOutlineLockReset } from "react-icons/md";
import PostApiCall from "../API/POSTAPI";
var otp = Math.floor(100000 + Math.random() * 900000);

export default function ForgotPassword({ ToggleForgotModal }) {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [iscnfrnPassVisible, setIscnfrnPassVisible] = useState(false);
    const [EmailRegex] = useState(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const [PasswordRegex] = useState(
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/
    );
    const [Email, setEmail] = useState("");
    const [EnteredOtp, setEnteredOtp] = useState(null);
    const [ShowInputforOtp, setShowInputforOtp] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [NewPassword, setNewPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [Salt, setSalt] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [showField, setShowField] = useState(true);
    const [customerId, setCustomerId] = useState("");

    useEffect(() => {
        Notiflix.Loading.init({
            svgColor: "#0f3d74",
        });
    }, []);

    const handleToggleModal = () => {
        setOpenModal(false);
    };

    const sendConfirmation = () => {
        if (Email != "") {
            if (EmailRegex.test(Email)) {
                onPostSalt();
            } else {
                Notiflix.Notify.failure("Please enter valid Email Address.");
            }
        } else {
            Notiflix.Notify.failure("Please enter your Email.");
        }
    };

    const onPostSalt = () => {
        Notiflix.Loading.dots("Please wait...");
        PostApiCall.postRequest(
            {
                email: Email,
            },
            "Get_SaltByEmailId_ForgotPassword"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data != null) {
                        setSalt(obj.data);
                        setCustomerId(obj.data[0].fld_customerid)
                        onConfirmation();
                        Notiflix.Loading.remove()
                    }
                } else {
                    Notiflix.Notify.failure("Email Id is not registered with us");
                    Notiflix.Loading.remove()
                }
            })
        );
    };

    const onConfirmation = () => {
        Notiflix.Loading.dots("Please wait...");
        PostApiCall.postRequest(
            {
                OTP: otp,
                email: Email,
            },
            "Mailer_ForgotPassword"
        ).then((result) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.success(
                "Your verification OTP is Successfully sent to your Email Id."
            );
            setShowField(false);
            setShowInputforOtp(true);
        });
    };

    const VerifyOtp = () => {
        if (EnteredOtp != null) {
            if (EnteredOtp == otp) {
                setOpenPassword(true);
                setShowInputforOtp(false);
                setNewPassword("")
            } else {
                Notiflix.Notify.failure("Incorrect OTP.");
            }
        } else {
            Notiflix.Notify.failure("Please enter OTP.");
        }
    };

    const SavePassword = () => {
        if (NewPassword != "") {
            if (PasswordRegex.test(NewPassword)) {
                if (ConfirmPassword != "") {
                    if (ConfirmPassword == NewPassword) {
                        onPostPassword();
                    } else {
                        Notiflix.Notify.failure(
                            "New Password and Confirm Password do not match "
                        );
                    }
                } else {
                    Notiflix.Notify.failure("Please enter confirm password.");
                }
            } else {
                Notiflix.Notify.failure(
                    "Password must be at least 6 characters  & contains a number and a letter"
                );
            }
        } else {
            Notiflix.Notify.failure("Please enter new password.");
        }
    };

    const onPostPassword = () => {
        Notiflix.Loading.dots("Please wait...");

        PostApiCall.postRequest(
            {
                email: Email,
                password: ConfirmPassword,
                salt: Salt[0].fld_salt,
                updatedby: null,
                userid: customerId,
            },
            "ChangeCustomerPassword"
        ).then((result) =>
            result.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    Notiflix.Notify.success("Password Successfully changed");
                    ToggleForgotModal("Log");
                    Notiflix.Loading.remove();
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure("Something went wrong, try again later.");
                }
            })
        );
    };

    return (
        <div className="login-box">
            <div>
                <div className="login-modal-icon">
                    <MdOutlineLockReset />
                </div>
                <div className="text-center">
                    <h4 className="mb-0">
                        Forgot your Password? No Worries!
                    </h4>
                    <p className="mb-3">Let's verify you!</p>
                </div>
                {showField && (
                    <div className="row m-0 justify-content-between align-items-center">
                        <input
                            type="text"
                            placeholder="Enter your Email Address"
                            className="form-control col-md-8 mb-0"
                            value={Email}
                            onChange={(email) => {
                                setEmail(email.target.value);
                            }}></input>
                        <div className="login-button mt-4 p-0">
                            <button
                                className="theme-btn w-100 d-block mb-2 border-0"
                                onClick={sendConfirmation}>
                                Get Code
                            </button>
                        </div>
                    </div>
                )}

                {ShowInputforOtp &&
                    <div className="mb-3"
                        style={{
                            position: "relative",
                        }}>
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            placeholder="Enter OTP"
                            className="form-control"
                            value={EnteredOtp}
                            onChange={(otp) => setEnteredOtp(otp.target.value)}></input>
                        <span className="login-icon-change-pass">
                            <i
                                style={{
                                    color: isPasswordVisible ? "" : "#777f80",
                                    position: "absolute",
                                    right: "3%",
                                    bottom: "30%",
                                    cursor: "pointer",
                                }}
                                className="fa fa-eye"
                                onClick={() => {
                                    setIsPasswordVisible((prevState) => !prevState);
                                }}
                            />
                        </span>
                    </div>
                }

                <div
                    style={{
                        display: openPassword == false ? "none" : "block",
                    }}
                >

                    <div className="position-relative mt-3 ">
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            placeholder="Enter new passowrd"
                            className="form-control"
                            value={NewPassword}
                            onChange={(password) =>
                                setNewPassword(password.target.value)
                            }></input>
                        <span className="login-icon-change-pass">
                            <i
                                style={{
                                    color: isPasswordVisible ? "" : "#777f80",
                                    position: "absolute",
                                    right: "3%",
                                    bottom: "30%",
                                    cursor: "pointer",
                                }}
                                className="fa fa-eye"
                                onClick={() => {
                                    setIsPasswordVisible((prevState) => !prevState);
                                }}
                            />
                        </span>
                    </div>
                    <div className="position-relative mt-3">
                        <input
                            type={iscnfrnPassVisible ? "text" : "password"}
                            placeholder="Confirm new password"
                            className="form-control"
                            value={ConfirmPassword}
                            onChange={(password) =>
                                setConfirmPassword(password.target.value)
                            }></input>
                        <span className="login-icon-change-pass">
                            <i
                                style={{
                                    color: iscnfrnPassVisible ? "" : "#777f80",
                                    position: "absolute",
                                    right: "3%",
                                    bottom: "30%",
                                    cursor: "pointer",
                                }}
                                className="fa fa-eye"
                                onClick={() => {
                                    setIscnfrnPassVisible((prevState) => !prevState);
                                }}
                            />
                        </span>
                    </div>

                </div>

                {openPassword == false ? (
                    <div
                        className="justify-content-end  align-items-center pl-lg-3 pr-lg-3 mt-3 flex-column login-button"
                        style={{ display: showField == true ? "none" : "flex" }}
                    >

                        <button className="theme-btn w-100 d-block mb-2 border-0" onClick={VerifyOtp}>
                            Verify
                        </button>

                        <button
                            className=" theme-btn w-100 d-block mb-2 border-0"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            style={{ cursor: "pointer" }}
                            onClick={sendConfirmation}>
                            Resend OTP
                        </button>

                    </div>
                ) : (
                    <div
                        className="row justify-content-between align-items-center "
                        style={{ display: showField == true ? "none" : "flex" }}
                    >
                        <div className="login-button mt-3">
                            <button className="theme-btn w-100 d-block mb-2 border-0" onClick={SavePassword}>
                                Save new password
                            </button>
                        </div>
                    </div>
                )}

                <div className="rgbox-bottom text-center mt-2">
                    <p>
                        <label>Start your Shopping</label>
                    </p>
                </div>

                <div className="row justify-content-around">
                    <div className="but col-md-6">
                        <div className="login-button">
                            <button
                                type="button"
                                className="default-btn border-0 mt-0 login-btn w-100"
                                onClick={() => {
                                    ToggleForgotModal("Reg");
                                }}>
                                Sign Up
                            </button>
                        </div>
                    </div>

                    <div className="but col-md-6">
                        <div className="login-button">
                            <button
                                type="button"
                                className="default-btn border-0 mt-20 login-btn mt-3 mt-lg-0 w-100"
                                onClick={() => {
                                    ToggleForgotModal("Log");
                                }}>
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}
