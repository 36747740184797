import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Skeleton, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PostApiCall from "../API/POSTAPI";
import ProductCard from "../ProductCard/ProductCard";
import "./ProductListing.css";
import nodata from "../img/not-avaiabl.png";
import { store } from "../Helper/Store";
import GetSeoData from "../API/GetSeoData";
export default function ProductListing() {
  const params = useParams();
  const [apiData, setApiData] = useState([]);
  const [productClass, setProductClass] = useState("col-6 col-lg-3 mb-4");
  const [dataLoading, setDataLoading] = useState(true);
  const { cartItems } = useContext(store);
  useEffect(() => {
    getItems();
  }, [params.categoryname]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [params.categoryname]);
  const getItems = () => {
    PostApiCall.postRequest(
      {
        verticalroute: params.categoryname,
        stateid: null,
      },
      "Get_All_Items"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          const sortbyLatest = obj1.data.sort(
            (a, b) => b.fld_variantid - a.fld_variantid
          );
          setApiData(sortbyLatest);
          setDataLoading(false);
        }
      })
    );
  };
  const items = [
    {
      label: "Price, High to Low",
      key: 1,
      value: "Price, High to Low",
    },
    {
      label: "Price, Low to High",
      key: 2,
      value: "Price, Low to High",
    },
  ];
  const handleMenuClick = (value) => {
    const sortedproducts = [...apiData];
    if (value.key == 1) {
      sortedproducts.sort((a, b) => {
        return (b.fld_selling_price -
          a.fld_selling_price
        );
      });
    } else if (value.key == 2) {
      sortedproducts.sort((a, b) => {
        return (
          parseInt(a.fld_selling_price) -
          parseInt(b.fld_selling_price)
        );
      });
    }
    setApiData(sortedproducts);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <>
      <div className="page-title-area product-listing">
        <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
          <div className="page-title-content">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Our Products</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <GetSeoData type='Vertical' id={params.categoryid} route={params.subcategoryname ? params.subcategoryname : params.productcategoryname ? params.productcategoryname : params.categoryname} /> */}
      <GetSeoData type={params.productcategoryname ? 'Category' : "Vertical"} id={params.categoryid} route={params.subcategoryname ? params.subcategoryname : params.productcategoryname ? params.productcategoryname : params.categoryname} />
      <section className="spacing mt-4 mt-lg-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1 className="main-heading text-center mb-lg-0">
                {apiData[0]?.fld_categoryname}
              </h1>
              <div className="d-flex justify-content-end align-items-center mb-4">
                {/* <div className="card-layout-cahane-box d-none d-lg-flex me-4">
                                    <ul onClick={() => setProductClass("col-lg-4 col-12 mb-4")}>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                    <ul onClick={() => setProductClass("col-lg-3 col-12 mb-4")}>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div> */}
                {apiData.length !== 0 && (
                  <Dropdown menu={menuProps}>
                    <Button>
                      <Space>
                        Sort by
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                )}
              </div>
            </div>

            {apiData.length > 0 ? (
              apiData
                .filter((dat) => dat.fld_status == "Active")
                .map((val) => {
                  const isInCart = cartItems.find(
                    (item) => item.fld_variantid === val.fld_variantid
                  );
                  return (
                    <div key={val.fld_variantid} className={`${productClass}`}>
                      <ProductCard itemdata={val} isInCart={isInCart} />
                    </div>
                  );
                })
            ) : dataLoading === false ? (
              <div className="d-flex justify-content-center align-items-center w-100 listingpage-height py-5 mb-5 nodata flex-column">
                <img className="mb-3 img-fluid" src={nodata} />
                <p className="fs-5">
                  The items you are searching for are not available.
                </p>
              </div>
            ) : null}

            {dataLoading === true && (
              <div className="col-12">
                <div className="row blank-section product-listing-skeleton mb-4">
                  <div className="col-6 col-md-12 col-lg-3">
                    <div className="product-box d-flex flex-column">
                      <Skeleton.Image className="w-100" />
                      <Skeleton.Button className="w-75 mt-2" />
                      <Skeleton.Button className="w-100 mt-2" />
                    </div>
                  </div>
                  <div className="col-6 col-md-12 col-lg-3">
                    <div className="product-box d-flex flex-column">
                      <Skeleton.Image className="w-100" />
                      <Skeleton.Button className="w-75 mt-2" />
                      <Skeleton.Button className="w-100 mt-2" />
                    </div>
                  </div>
                  <div className="col-6 col-md-12 col-lg-3">
                    <div className="product-box d-flex flex-column">
                      <Skeleton.Image className="w-100" />
                      <Skeleton.Button className="w-75 mt-2" />
                      <Skeleton.Button className="w-100 mt-2" />
                    </div>
                  </div>
                  <div className="col-6 col-md-12 col-lg-3">
                    <div className="product-box d-flex flex-column">
                      <Skeleton.Image className="w-100" />
                      <Skeleton.Button className="w-75 mt-2" />
                      <Skeleton.Button className="w-100 mt-2" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
