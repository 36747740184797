import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
import supportIcon from "../img/footer-policy-icons/24-hours-support.png";
import easyIcon from "../img/footer-policy-icons/easy.png";
import freeDeliveryIcon from "../img/footer-policy-icons/free-delivery.png";
import securePaymentIcon from "../img/footer-policy-icons/secure-payment.png";
import "./FooterPolicySection.css";
export default function FooterPolicySection() {
  return (
    <section className="spacing mt-lg-3">
      <div className="section-spacing-x section-spacing-x-mobile ">
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                autoplay="flase"
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper h-auto customer-slider-dots footer-pilocy-slider"
              >

                <SwiperSlide>
                  <div className="footer-policy-card">
                    <div>
                      <img alt="" src={supportIcon} className="img-fluid" />
                    </div>
                    <div className="content">
                      <p>Customer Support</p>
                      <span>
                        Need assistance? Call us and our team will help you out.
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="footer-policy-card">
                    <div>
                      <img alt="" src={securePaymentIcon} className="img-fluid" />
                    </div>
                    <div className="content">
                      <p>Secure Payment</p>
                      <span>
                        Making online payments on our site to buy is Safe and
                        Secure.
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="footer-policy-card">
                    <div>
                      <img alt="" src={freeDeliveryIcon} className="img-fluid" />
                    </div>
                    <div className="content">
                      <p>Free Shipping</p>
                      <span>
                        Shipping cost is free for selected value of orders made.
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="footer-policy-card">
                    <div>
                      <img alt="" src={easyIcon} className="img-fluid" />
                    </div>
                    <div className="content">
                      <p> Easy Return</p>
                      <span>
                        Return of products if damaged or spoilt is very easy.
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
}
