import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { store } from "../Helper/Store";
import AddToCart from "../AddToCart/AddToCart";
import AddToCartQty from "../AddToCart/AddToCartQty";
import "./ProductCard.css";
import { MdCurrencyRupee } from "react-icons/md";

export default function ProductCard({ itemdata, isInCart }) {
  const [variantImage, setVariantImage] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [variantName, setVariantName] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [MRP, setMRP] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const { storeStatus } = useContext(store);

  useEffect(() => {
    try {
      setItemName(itemdata.fld_variantname);
      setMRP(
        itemdata.variant_list.split("#")[0].split("$")[1].split(",")[2]
      );
      setDiscountPrice(
        itemdata.variant_list.split("#")[0].split("$")[1].split(",")[3]
      );
      setVariantId(
        itemdata.variant_list.split("#")[0].split("$")[1].split(",")[1]
      );
      setVariantName(
        itemdata.variant_list.split("#")[0].split("$")[1].split(",")[0]
      );
      setCategoryName(itemdata.fld_categoryname);
      setVariantImage(
        itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[7]
          .split("~")[0]
      );
    } catch {
      try {
        setItemName(itemdata.fld_variantname);
        setMRP(itemdata.variant_list.split("$")[1].split(",")[2]);
        setDiscountPrice(
          itemdata.variant_list.split("$")[1].split(",")[3]
        );
        setVariantId(
          itemdata.variant_list.split("$")[1].split(",")[1]
        );
        setVariantName(
          itemdata.variant_list.split("$")[1].split(",")[0]
        );
        setCategoryName(itemdata.fld_categoryname);
        setVariantImage(
          itemdata.variant_list
            .split("$")[1]
            .split(",")[7]
            .split("~")[0]
        );
      } catch { }
    }
  }, []);
  return (


    <div className="product-card d-flex flex-column justify-content-between" >
      <div>
        <Link
          to={`${process.env.PUBLIC_URL
            }/p/${itemdata.fld_route?.replace(
              /\s/g,
              "-"
            ).replace('/', '')}`}
        >
          <img
            src={itemdata.VariantImage}
            className="img-fluid"
            alt="Blessing Products"
          />
        </Link>
        <div className="product-content text-center pb-0">
          <div>
            <Link
              to={`${process.env.PUBLIC_URL
                }/p/${itemdata.fld_route?.replace(
                  /\s/g,
                  "-"
                ).replace('/', '')}`}
            >
              {itemdata.fld_variantname}
            </Link>
          </div>
          <div className="rupee">
            <span>
              <MdCurrencyRupee />

              {itemdata.fld_website_discount_price}
            </span>
            <del>
              {itemdata.fld_website_discount_price === itemdata.fld_mrp ? "" : <><MdCurrencyRupee /> {itemdata.fld_mrp}</>}
            </del>
          </div>
        </div>
      </div>
      <div className="btn-box my-2 pb-1 content text-center">
        {isInCart ? (
          <AddToCartQty
            itemid={itemdata?.fld_variantid}
            quantity={isInCart.fld_quantity}
            cartlineid={isInCart.fld_cart_dtl_id}
          />
        ) : (
          <span className="theme-btn cursor-pointer">
            <AddToCart itemid={itemdata?.fld_variantid} />
          </span>
        )}
      </div>
    </div>
  );
}
