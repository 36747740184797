import { DatePicker, Input, TimePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { FaHome, FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall, IoMail } from "react-icons/io5";
import { TfiReload } from "react-icons/tfi";
import { store } from "../Helper/Store";
import "./appointmentpage.css";
import PostApiCall from "../API/POSTAPI";
import GetSeoData from "../API/GetSeoData";
// import GetSeoData from "../../helpers/GetSeoData";

export default function ContactUsPage() {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  useEffect(() => {
    generateRandomCaptcha();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { clientData } = useContext(store);
  const [captcha, setCaptcha] = useState("");
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });
  const handleRegexValidation = (email, phone) => {
    let result;
    if (email) {
      result = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    } else {
      result = /^\d{10}$/.test(phone);
    }
    return result;
  };
  const generateRandomCaptcha = () => {
    const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
    setCaptcha(newCaptcha);
  };
  function isCaptchaValid(enteredCaptcha) {
    return captcha == enteredCaptcha;
  }
  const handleInputChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleValidateForm() {
    if (formData.name === "") {
      Notiflix.Notify.failure("Name is required");
      return;
    }
    if (formData.email == "") {
      Notiflix.Notify.failure("Email address is required");
      return;
    } else if (!handleRegexValidation(formData.email, "")) {
      Notiflix.Notify.failure("Invalid email address");
      return;
    }
    if (formData.mobile === "") {
      Notiflix.Notify.failure("Phone number is required");
      return;
    } else if (!handleRegexValidation("", formData.mobile)) {
      Notiflix.Notify.failure("Invalid phone number");
      return;
    }

    if (formData.message === "") {
      Notiflix.Notify.failure("Message is required");
      return;
    }
    if (!isCaptchaValid(enteredCaptcha)) {
      Notiflix.Notify.failure("Invalid Captcha");
      return;
    }
    onSubmit();
  }

  function clearFormData() {
    setFormData({
      name: "",
      mobile: "",
      email: "",
      message: "",
    });
  }

  function onSubmit() {
    Notiflix.Loading.dots("");
    PostApiCall.postRequest(
      {
        name: formData.name,
        mobile: formData.mobile,
        email: formData.email,
        message: formData.message,
      },
      "ContactUsMailer"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          clearFormData();
          // generateRandomCaptcha();
          Notiflix.Notify.success("Form Submitted Successfully.");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  }

  return (
    <>
      <GetSeoData type="Contact Us" id={null} />
      <section className="inner-section appointment-pag">
        {/* <NavBar /> */}
        <div className="page-title-area contact-banner">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Book An Appointment</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="section-spacing-x section-spacing-x-mobile my-5 spacing ">
        <div className="container-fluid">
          {/* <h2 className="text-center main-heading main-heading" >Get in Touch with Us!</h2> */}
          <div className="main-heading text-center col-12 t-head mb-4">
            <h2>Contact Us</h2>
            <h5>The Divine Guidance Awaits!</h5>
            {/* <p>
              Schedule Your Blessings Astrology Session Now! Get Remedial and
              Divine Solutions for Your Happy Life
            </p> */}
          </div>
          <div className="row flex-lg-row flex-column-reverse">
            <div className="col-lg-6">
              <h4 className="main-heading mb-0  mt-md-1">Contact info</h4>
              <div className="contact-info-box">
                <div className="icon">
                  <FaLocationDot />
                </div>
                <div>
                  <p>Our Office</p>
                  <p>{clientData[0]?.store}</p>
                  <a
                    href="https://maps.app.goo.gl/TFBvMGpxfycw9dcHA"
                    target="_blank"
                  >
                    {/* B 7/1, near Rajouri Garden, Upper Ground,
                    <br />
                    Rajouri Garden, New Delhi, Delhi - 110 027, INDIA. */}
                    {clientData[0]?.address}
                  </a>
                </div>
              </div>
              <div className="contact-info-box">
                <div className="icon">
                  <IoCall />
                </div>
                <div>
                  {/* <p>Call Us</p> */}
                  <h4 className="main-heading mb-0  mt-md-1">Call Us</h4>

                  <a href="tel:+919821155251">+91 - 98211 55251</a>
                </div>
              </div>
              <div className="contact-info-box">
                <div className="icon">
                  <FaWhatsapp />
                </div>
                <div>
                  {/* <p>Message Us</p> */}
                  <h4 className="main-heading mb-0 mt-4 mt-md-1">Message Us</h4>

                  <a href="tel:+919821155251">+919821155251</a>
                </div>
              </div>
              <div className="contact-info-box">
                <div className="icon">
                  <IoMail />
                </div>
                <div>
                  {/* <p>Mail Us</p> */}
                  <h4 className="main-heading mb-0  mt-md-1">Mail Us</h4>

                  {/* <a href={`mailto:${clientData[0]?.email}`}>
                    {clientData[0]?.email}
                  </a> */}
                  <a
                    href="mailto:surabhibhatnagar@blessingsastrology.com
"
                    className="ms-lg-1 email"
                  >
                    surabhibhatnagar@blessingsastrology.com
                  </a>
                </div>
              </div>
            </div>
            {/*  Contact form on the right side */}
            <div className="col-lg-6">
              <h4>
                Welcome to <b>Blessings Astrology!</b>
              </h4>
              <p>
                We value your feedback, inquiries, and support. Whether you have
                questions about our products, need assistance with an order, or
                just want to say hello, we are here to help. Feel free to reach
                out to us by filling the form below:
              </p>
              <div className="row contact-form mt-3">
                <div className="col-lg-6 mb-4">
                  <Input
                    placeholder=" Name"
                    value={formData.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                </div>

                <div className="col-lg-6 mb-4">
                  <Input
                    placeholder="Email"
                    value={formData.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                </div>

                <div className="col-lg-6 mb-4">
                  <Input
                    placeholder="Phone no."
                    value={formData.mobile}
                    onChange={(e) =>
                      handleInputChange("mobile", e.target.value)
                    }
                  />
                </div>

                <div className="col-12 mb-4">
                  <TextArea
                    rows={5}
                    placeholder="Message"
                    value={formData.message}
                    onChange={(e) =>
                      handleInputChange("message", e.target.value)
                    }
                  />
                </div>
                <div className="form-group col-lg-6 mb-4 pb-0">
                  <div className="captcha-box">
                    <input
                      type="text"
                      value={captcha}
                      disabled
                      className="form-control"
                    />
                    <div
                      className="captcha"
                      onClick={() => generateRandomCaptcha()}
                    >
                      <TfiReload />
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-6 mb-4">
                  <input
                    type="text"
                    value={enteredCaptcha}
                    onChange={(e) =>
                      setEnteredCaptcha(e.target.value.toUpperCase())
                    }
                    className="form-control"
                    placeholder="Enter Captcha *"
                  />
                </div>
                <div className="col-12 mb-3">
                  <div className="read-more">
                    <a
                      className="theme-btn border-0"
                      onClick={handleValidateForm}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
