import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { store } from "../Helper/Store";
import PostApiCall from "../API/POSTAPI";
function AddToCartQty(props) {
  const quantity = props.quantity;
  const itemid = props.itemid;
  const cartlineid = props.cartlineid;
  const { setLoginPopupWebsite } = useContext(store);
  const { setCartItemBeforeLogin } = useContext(store);
  const { setCategory4Data } = useContext(store);
  const { cart, setcart,cartItems, setCartItems, setCartData, setcartamount } =
    useContext(store);
  const GetCartdetails = () => {
    Notiflix.Loading.dots("")
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    var orderid = 0;
    // console.log(props)
    if (props.shippingAddress) {
      AddShippingChrg();
    }
    if (localStorage.getItem("OrderData") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    if (localStorage.getItem("CustomerData") === null) {
      setLoginPopupWebsite(true);
    } else {
      var login = localStorage.getItem("CustomerData");
      var logindetails = JSON.parse(login);
      if (localStorage.getItem("OrderData") !== null) {
        PostApiCall.postRequest(
          {
            orderid:
              localStorage.getItem("OrderData") == null
                ? null
                : Orderdetails[0].order_id,
            id:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
          },
          "GetCartWebsite"
        )
          .then((res) => res.json())
          .then((res1) => {
            if (res1.data) {
              // setProductCart(res1.data);
              setcart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
              setCartItems(res1.data);
              setCartData(res1.data);
              setcartamount(res1.data[0]?.fld_cart_amount);
              Notiflix.Loading.remove()
            }
          });
      }
    }
  };
  const deletecart = (props) => {
    Notiflix.Loading.dots("")
    var Order = localStorage.getItem("OrderData");
    var login = localStorage.getItem("CustomerData");
    var Orderdetails = JSON.parse(Order);
    var logindetails = JSON.parse(login);
    var orderid = 0;
    if (localStorage.getItem("OrderData") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    PostApiCall.postRequest(
      {
        cartID: orderid,
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        cartlineid: cartlineid,
        stateid: 0,
      },
      "DeleteCart"
    ).then((result) =>
      result.json().then((obj3) => {
        if (result.status === 200 || result.status === 201) {
          Notiflix.Notify.success("Product is successfully deleted.");
          GetCartdetails();
          // eslint-disable-next-line eqeqeq
          if (cartItems.length === 1) {
            localStorage.removeItem("OrderData");
          }
          GetCartdetails();
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj3.data);
        }
      })
    );
  };

  const addtocart = (qty) => {
    Notiflix.Loading.dots("");
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderdate: moment().format("lll"),
        itemdetails: `
                     [
                     {
                         "Item":${itemid},
                         "Quantity": ${qty},
                         "CartLineId":${cartlineid}
                     }
                     ]
                     `,
        status: "INCART",
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        createdon: moment().format("lll"),
        updatedon: moment().format("lll"),
        orderid:
          localStorage.getItem("OrderData") == null
            ? 0
            : Orderdetails[0].order_id,
        updatedby:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_userid,
      },
      "AddToCartWebsite"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          if (props == 1) {
            Notiflix.Notify.success("Product added to Cart.");
          }
          if (localStorage.getItem("OrderData") == null) {
            localStorage.setItem("OrderData", JSON.stringify(obj.data));
          }
          GetCartdetails();
          Notiflix.Notify.success("Product updated in Cart.");
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };
  const AddShippingChrg = () => {
    const det = localStorage.getItem("CustomerData");
    const details = JSON.parse(det);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        customerid: details[0].fld_customerid,
        orderid:
          localStorage.getItem("OrderData") == null
            ? 0
            : Orderdetails[0].order_id,
        addressid: props?.shippingAddress,
      },
      "AddShippingChrg"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          // GetCartdetails();
        }
      })
    );
  };
  return (
    <div className="add-btn-box">
      <div className="quantity border d-flex align-items-center p-0">
        <div class="quantity-btn">
          <button
            className="minus"
            name="minus"
            type="button"
            onClick={() => {
              addtocart(-1);
            }}
          >
            <FiMinus />
          </button>
          <input
            className="number"
            type="text"
            value={quantity}
            min="0"
            id="Quantity-1"
            data-index="1"
          />
          <button
            className="plus"
            name="plus"
            type="button"
            onClick={() => {
              addtocart(1);
            }}
          >
            <FiPlus />
          </button>
        </div>
        <div className="quantity-delete-btn delete">
          <cart-remove-button
            id="Remove-1"
            data-index="1"
            className="Remove-1"
            onClick={deletecart}
          >
            <MdDeleteForever />
          </cart-remove-button>
        </div>
      </div>
    </div>
  );
}

export default AddToCartQty;
