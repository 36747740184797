// import logo from './logo.svg';
import "./App.css";
import TopBtn from "./backtotop/top";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./home/home";
// import NavBar from "./nav/nav";
import Nav from "./nav/nav";
import Services from "./servicepage/servicepage";
import About from "./aboutpage/aboutpage";
import Products from "./productspage/productspage";
import ContactUsPage from "./appointmentpage/ContactUsPage";
import Gallerypage from "./photogallery/gallerypage";
import consultform from "./home/ConsultForm/ConsultForm";
import Paymentpage from "./paymentpage/PaymentComponet";
import Horoscope from "./horoscopepage/horoscopepage";
import Blogpage from "./blogpage/blog";
import PrivacyPolicypage from "./Privacy/PrivacyPolicy";
import MediaPage from "./mediapage/media";
import thankyoupage from "./thankyoupage/thankyou";
import BooksPage from "./bookspage/books";
import Corporatetraining from "./servicepage/serviceinner/corporatetraining";
import Footer from "./footer/footer";
import FooterPolicySection from "./FooterPolicySection/FooterPolicySection";
import ProductDetail from "./ProductDetail/ProductDetail";
import Provider, { store } from "./Helper/Store";
import Cart from "./Cart/Cart";
import CustomerProfilePage from "./CustomerProfilePage/CustomerProfilePage";
import PaymentSuccess from "./Payments/PaymentSuccess";
import PaymentFail from "./Payments/PaymentFail";
import ProtectedRoutes from "./RouteAuth/ProtectedRoutes";
import ViewInvoiceForm from "./Invoice/ViewInvoiceForm";
import ViewAllOrders from "./CustomerProfilePage/ViewAllOrders";
import StaticPage from "./StaticPage/StaticPage";
import horoscopesection from "./horoscopesection/horoscopesection";
import Faq from "./FAQ/Faq";
import ShopOnline from "./ShopOnlinePage/ShopOnline";
import PageNotFound from "./PageNotFound/PageNotFound";

import { useContext, useEffect, useState } from "react";
import GetApiCall from "./API/GETAPI";
import Homecategorypage from "./homecategory/category";
import TopBar from "./TopBar/TopBar";
import ProductListing from "./ProductListing/ProductListing";
import NavMain from "./nav/navmain";
import parallax from "./parallax/parallax";
import BlogDeatailPage from "./blogpage/BlogDeatailPage";
import ServiceDetails from "./serviceDetails/ServiceDetails";
import PostApiCall from "./API/POSTAPI";
function App() {
  const { staticPages, serviceData, setServiceData, setStaticPages } =
    useContext(store);
  const [termAndCondition, setTermAndCondition] = useState("");
  useEffect(() => {
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          // setTermAndCondition(termsPage[0].fld_route)
          setStaticPages(obj1.data);
          // const termsPage = obj1.data.filter((item) => item.fld_page_name = "Terms and Conditions")
        }
      })
    );
    GetServiceData();
  }, []);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: "",
      },
      "Services"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setServiceData(obj1.data.data);
        }
      })
    );
  };

  return (
    <>
      {/* {window.location.pathname.split("/")[1] != "book-a-slot" ? <TopBtn /> : <></>} */}
      <TopBtn />
      <TopBar />
      <NavMain />
      {/* <Nav /> */}

      <Switch>
        {staticPages?.map((data, index) => {
          return (
            <Route path={[data.fld_route]} key={index} component={StaticPage} />
          );
        })}
        <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/horoscope-prediction" component={Horoscope} />
        <Route exact path="/services" component={Services} />
        <Route
          exact
          path="/service/:servicename"
          component={ServiceDetails}
        />
        <Route exact path="/faq" component={Faq} />

        <Route exact path={"/blog/:blogname"} component={BlogDeatailPage} />
        <Route exact path="/blogs" component={Blogpage} />
        <Route exact path="/privacy-policy" component={PrivacyPolicypage} />
        <Route exact path="/podcast" component={MediaPage} />
        <Route exact path="/our-books" component={BooksPage} />
        <Route exact path="/shop-online" component={ShopOnline} />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/ct/:categoryname`}
          component={ProductListing}></Route>
        <ProtectedRoutes
          exact
          path="/customer-profile"
          component={CustomerProfilePage}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        />
        <ProtectedRoutes
          exact
          path="/paymentsuccess/:txnid"
          component={PaymentSuccess}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        />
        <ProtectedRoutes
          exact
          path={"/paymentfailed"}
          component={PaymentFail}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        />
        <ProtectedRoutes
          exact
          path={"/view-all-orders"}
          component={ViewAllOrders}
          isAuth={
            localStorage.getItem("CustomerData") == null
              ? false
              : true
          }
        />
        <ProtectedRoutes
          exact
          path={"/view-invoice-form/:id/:orderid"}
          component={ViewInvoiceForm}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        />
        <Route exact path="/thankyou" component={thankyoupage} />
        <Route exact path={`${process.env.PUBLIC_URL}/p/:productname`} component={ProductDetail} />
        <Route
          exact
          path="/corporate-training-service"
          component={Corporatetraining}
        />
        <Route exact path="/our-products" component={Products} />
        <Route exact path="/journey-of-excellence" component={Gallerypage} />
        <Route exact path="/contact-us" component={ContactUsPage} />
        <Route exact path="/consultform" component={consultform} />
        <Route component={PageNotFound} />
        <ProtectedRoutes
          exact
          path="/book-a-slot"
          component={Paymentpage}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        />
      </Switch>
      {/* <Footer /> */}
      {window.location.pathname.split("/thankyou")[1] != "" ? (
        <FooterPolicySection />
      ) : (
        <></>
      )}
      {window.location.pathname.split("/thankyou")[1] != "" ? (
        <Footer />
      ) : (
        <></>
      )}
    </>
  );
}

export default App;
