import React, { Component, useContext, useEffect, useState } from "react";
import Nav from "../nav/nav";
import Carousel from "../home/carousel/carousel";
import About from "./about/about";
import Services from "./services/services";
import WhyChoose from "./whychoose/whychoose";
import Testimonial from "./testimonial/testimonial";
import Remedies from "./remedies/remedies";
import Footer from "../footer/footer";
import Modal from "react-bootstrap/Modal";
import popup_image from "../../src/img/book-cover.jpg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import PostApiCall from "../API/POSTAPI";
import GetApiCall from "../API/GETAPI";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ConsultForm from "./ConsultForm/ConsultForm";
import { Helmet } from "react-helmet";
import HomeBlogs from "./HomeBlogs/HomeBlogs";
import Homecategorypage from "../homecategory/category";
import HomeBooks from "./HomeBooks/HomeBooks";
import HomeProductsCard from "./HomeProductsCard/HomeProductsCard";
import "./home.css";
import HomeInsta from "../HomeInstaPage/HomeInsta";
import Parallax from "../parallax/parallax";
import { store } from "../Helper/Store";
import HomeGallerySection from "../HomeGallerySection/HomeGallerySection";
import Chooseussection from "../chooseussection/chooseussection";
import HomeMediaService from "../HomeMediaService/HomeMediaService";
import GetSeoData from "../API/GetSeoData";
// import Horoscopesection from "../horoscopesection/horoscopesection";
function Home() {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  const [modalShow, SetModalShow] = useState(false);
  const [mainBanner, setMainBanner] = useState([]);
  const [homeProducts, setHomeProducts] = useState([]);
  const [booksData, setBooksData] = useState([]);
  const [parallax2Banner, setParallax2Banner] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetApiCall.getRequest("GetBannersV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          var filterBanner = obj1.data.filter(
            (item) => item.location === "Main"
          );
          var Parallax1Banner = obj1.data.filter(
            (item) => item.location === "Parallax"
          );
          var Parallax2Banner = obj1.data.filter(
            (item) => item.location === "Parallax2"
          );
          // var RightMainBanner = obj1.data.filter(
          //   (item) => item.location === "MainRight"
          // );
          // var CatBanner = obj1.data.filter(
          //   (item) => item.location === "CategoryBanner"
          // );
          setMainBanner(filterBanner);
          setParallax2Banner(Parallax1Banner);
          // setParallax2Banner(Parallax2Banner);
          // setMainRightBanner(RightMainBanner)
          // setCategoryBanner(CatBanner)
        }
      })
    );
    GetHomeProducts();
    GetServiceData();
    GetBooksData();
  }, []);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: "",
      },
      "Services"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setServiceData(
            obj1.data.filter((data) => data.fld_status === "Active").sort((a, b) => a.fld_id - b.fld_id)
          );
        }
      })
    );
  };

  const GetHomeProducts = () => {
    GetApiCall.getRequest("GetHomepageSetup")
      .then((result) => result.json())
      .then((res) => {
        res.data
          .filter((value) => value.fld_section_name === "Featured Products")
          .forEach((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              if (results.status === 200 || results.status === 201) {
                results.json().then((data) => {
                  setHomeProducts(data.data);
                });
              }
            });
          });
      });
  };

  const GetBooksData = () => {
    PostApiCall.postRequest(
      {
        verticalroute: "books",
        stateid: null,
      },
      "Get_All_Items"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          const sortbyLatest = obj1.data.sort(
            (a, b) => b.fld_variantid - a.fld_variantid
          );
          setBooksData(sortbyLatest);
        }
      })
    );
  };

  window.onload = () => {
    if (
      localStorage.getItem("popup_open") == null ||
      localStorage.getItem("popup_open") == undefined ||
      localStorage.getItem("popup_open") == "yes"
    ) {
      SetModalShow(true);
      localStorage.setItem("popup_open", "yes");
    }
  };
  return (
    <>
      <GetSeoData type="Home" id={null} />


      <Carousel mainBanners={mainBanner} />
      <WhyChoose />
      {/* <Chooseussection /> */}
      {/* <About /> */}
      <HomeGallerySection />
      <Services serviceData={serviceData} />
      <Testimonial />

      <HomeMediaService />

      <HomeBooks booksData={booksData} />
      <HomeInsta />

      <Homecategorypage />

      <Parallax height="40vh" parallaxData={parallax2Banner} />

      <HomeBlogs />


    </>
  );
}
export default Home;
