import React from "react";
import "../servicepage.css";
import NavBar from "../../nav/nav";
// import s1 from "../../img/service/inner/astrology.png";
import corporateimage from "../../img/corporate-training-inner.png"
import { Helmet } from "react-helmet";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import Corporateimagegallery from "./corporateimagegallery"
import introduction from "../../img/corporatephotos/introduction.png";
import Corporate from "../../img/corporatephotos/corporate-two.jpg";
// import Therapeutic from "../../img/corporatephotos/Therapeutic.png";
import Meditation from "../../img/corporatephotos/Meditation.png";
import Workshops from "../../img/corporatephotos/Workshops.png";
import Wellness from "../../img/corporatephotos/Wellness.png";
import Benifits from "../../img/corporatephotos/benifits.png";
// import workplace from "../../img/session/workplace.jpg"
// import reading from "../../img/session/reading.jpg"
// import meditation from "../../img/session/Meditation.jpg"
// import workshop from "../../img/session/workshop-interactive.jpg"
// import workshop from "../../img/session/workshop-interactive.jpg"
// import wellness from "../../img/session/wellness.jpg"
import astro from "../../img/service/astro.png"



// import therapeutic from "../../img/session/Therapeutic-Techniques.jpg"

import { FaCheck } from "react-icons/fa6";


export default function corporatetraining() {
  return (
    <>
      <section className="inner-section service-page">
      
        <section className="spacing">

          <div className="container-fluid pt-4">
            <div className="row">
              <div className="col-lg-12 service-inner-card">

                <div className="main-heading col-12 my-4 text-center">
                  <h2 className="mb-0">Session Components</h2>
                  <p className="px-0 text-center">
                    lorem lorem lorem lorem lorem
                  </p>
                </div>




              </div>



              <div className="col-lg-6 mb-3">
                <div className="background-card ">
                  <div className="number-about d-flex align-items-center justify-content-center main-heading">
                    <h2>1</h2>
                  </div>

                  <div className="corporate-content">
                    <div className="corporate-image">
                      <img src={astro} className="img-fluid">
                      </img>
                    </div>
                    <h3 className="main-heading mb-1 mt-2">Introduction to Astrology in the Workplace</h3>

                    <ul className="mb-0">
                      <li>Understanding the Basics of Astrology</li>
                      <li>How Astrology Influences Personal and Professional Life</li>
                      <li>Leveraging Astrological Insights for Team Building and Management</li>
                    </ul>


                  </div>

                </div>



              </div>

              {/*  */}
              <div className="col-lg-6 mb-3 ">
                <div className="background-card background-card-right ">
                  <div className="number-about d-flex align-items-center justify-content-center main-heading">
                    <h2>2</h2>
                  </div>

                  <div className="corporate-content">
                    <div className="corporate-image">
                      <img src={astro} className="img-fluid">
                      </img>
                    </div>
                    <h3 className="main-heading mb-1 mt-2">Personalized Astrological Readings</h3>

                    <ul className="mb-0">
                      <li>Individual Birth Chart Analysis
                      </li>
                      <li>Identifying Strengths and Areas of Improvement
                      </li>
                      <li>Personalized Career Guidance
                      </li>
                    </ul>


                  </div>

                </div>



              </div>

              {/*  */}
              <div className="col-lg-6 mb-3">
                <div className="background-card d-flex ">
                  <div className="number-about d-flex align-items-center justify-content-center main-heading">
                    <h2>3</h2>
                  </div>

                  <div className="corporate-content">
                    <div className="corporate-image">
                      <img src={astro} className="img-fluid">
                      </img>
                    </div>
                    <h3 className="main-heading mb-1 mt-2">Therapeutic Techniques</h3>

                    <h5>Astro-Therapy
                    </h5>
                    <ul className="mb-0">
                      <li>Combining Astrological Insights with Therapeutic Practices

                      </li>
                      <li>Addressing Workplace Stress and Anxiety
                      </li>

                    </ul>
                    <h5>Energy Healing
                    </h5>
                    <ul className="mb-0">
                      <li>Introduction to Reiki and Energy Balancing


                      </li>
                      <li>Techniques to Clear Negative Energy and Enhance Positivity

                      </li>

                    </ul>


                  </div>

                </div>



              </div>

              {/*  */}
              <div className="col-lg-6 mb-3">
                <div className="background-card background-card-right">
                  <div className="number-about d-flex align-items-center justify-content-center main-heading">
                    <h2>4</h2>
                  </div>

                  <div className="corporate-content">
                    <div className="corporate-image">
                      <img src={astro} className="img-fluid">
                      </img>
                    </div>
                    <h3 className="main-heading mb-1 mt-2">Meditation Sessions</h3>

                    <h5>Guided Meditation

                    </h5>
                    <ul className="mb-0">
                      <li>Techniques for Stress Reduction and Mental Clarity


                      </li>
                      <li>Daily Practices for Enhanced Focus and Productivity

                      </li>

                    </ul>
                    <h5>Mindfulness Meditation

                    </h5>
                    <ul className="mb-0">
                      <li>Cultivating Presence and Awareness in the Workplace



                      </li>
                      <li>Exercises to Improve Emotional Regulation


                      </li>

                    </ul>


                  </div>

                </div>



              </div>

              {/*  */}
              <div className="col-lg-6 mb-lg-0 mb-3">
                <div className="background-card d-flex ">
                  <div className="number-about d-flex align-items-center justify-content-center main-heading">
                    <h2>5</h2>
                  </div>

                  <div className="corporate-content">
                    <div className="corporate-image">
                      <img src={astro} className="img-fluid">
                      </img>
                    </div>
                    <h3 className="main-heading mb-1 mt-2">Workshops and Interactive Sessions</h3>

                    <h5>Team Building Workshops


                    </h5>
                    <ul className="mb-0">
                      <li>Activities and Exercises to Foster Team Cohesion and Collaboration



                      </li>
                      <li>Role of Astrology in Understanding Team Dynamics


                      </li>

                    </ul>
                    <h5>Leadership Development


                    </h5>
                    <ul className="mb-0">
                      <li>Empowering Leaders with Astrological Insights




                      </li>
                      <li>Enhancing Leadership Skills through Self-Awareness and Intuition



                      </li>

                    </ul>


                  </div>

                </div>



              </div>

              {/*  */}
              <div className="col-lg-6">
                <div className="background-card d-flex  flex-row-reverse">
                  <div className="number-about d-flex align-items-center justify-content-center main-heading">
                    <h2>6</h2>
                  </div>

                  <div className="corporate-content">
                    <div className="corporate-image">
                      <img src={astro} className="img-fluid">
                      </img>
                    </div>
                    <h3 className="main-heading mb-1 mt-2">Wellness Programs</h3>
                    {/* <p>Understanding the Basics of Astrology
                    </p>
                    <p>How Astrology Influences Personal and Professional Life
                    </p>
                    <p>Leveraging Astrological Insights for Team Building and Management
                    </p> */}
                    <h5>Holistic Health and Wellness



                    </h5>
                    <ul className="mb-0">
                      <li>Integrating Physical, Mental, and Emotional Well-being




                      </li>
                      <li>Personalized Wellness Plans Based on Astrological Profiles



                      </li>

                    </ul>
                    <h5>Stress Management Techniques



                    </h5>
                    <ul className="mb-0">
                      <li>Practical Tools for Managing Stress in a Corporate Environment





                      </li>
                      <li>Breathing Exercises, Visualization, and Relaxation Techniques




                      </li>

                    </ul>


                  </div>

                </div>



              </div>

            </div>
          </div>
        </section>
      </section>
      <section className="spacing mt-5 mb-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="service-parallex">

              </div>
            </div>
          </div>

        </div>
      </section>
      <section className="spacing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading mt-2 mb-5 text-center">
                <h2>Benefits of the Program</h2>
                <p>lorem lorem lorem lorem lorem</p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={Benifits} alt="Benefits of the Program" className="img-fluid benefits"></img>
            </div>
            <div className="col-lg-6">
              <ul className="check-icon">
                <li > <span className="icon-check"> <FaCheck /></span>
                  <div className="px-2">
                    <h5>Enhanced Self-Awareness:</h5> Employees gain a deeper understanding of their strengths, weaknesses, and potential.
                  </div></li>
                <li><span className="icon-check"> <FaCheck /></span>
                  <div className="px-2">
                    <h5>Improved Team Dynamics: </h5> Teams function more cohesively with better communication and mutual understanding.
                  </div></li>
                <li><span className="icon-check"> <FaCheck /></span>
                  <div className="px-2">


                    <h5>Increased Productivity: </h5> Techniques and insights lead to better focus, efficiency, and overall performance.
                  </div> </li>
                <li><span className="icon-check"> <FaCheck /></span>
                  <div className="px-2">


                    <h5>Reduced Stress Levels: </h5> Meditation and therapeutic practices help in managing and reducing workplace stress.
                  </div> </li>
                <li>  <span className="icon-check"> <FaCheck /></span>
                  <div className="px-2">


                    <h5>Better Conflict Management: </h5> Astrological insights aid in resolving conflicts amicably and effectively.
                  </div> </li>
              </ul>
            </div>
          </div>
          <div className="col-12 mb-4 mt-5">
            <Corporateimagegallery />
          </div>
        </div>
      </section >
    </>
  );
}