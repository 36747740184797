import React, { Component, useEffect, useState } from "react";
import "./servicepage.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "react-bootstrap";
import Appointmentform from "../appointmentpage/appointmentform";
import { Skeleton } from "antd";
import PostApiCall from "../API/POSTAPI";

import createDOMPurify from "dompurify";
import GetSeoData from "../API/GetSeoData";
const DOMPurify = createDOMPurify(window);

export default function Servicepage() {
  const [serviceData, setServiceData] = useState([]);
  const [serviceTitle, setServiceTitle] = useState("");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetServiceData();
  }, []);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: "",
      },
      "Services"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setServiceData(
            obj1.data.filter((data) => data.fld_status === "Active").sort((a, b) => a.fld_id - b.fld_id)
          );
        }
      })
    );
  };
  // for modal
  const [showForm, setShowForm] = useState(false);

  const showModal = (items) => {
    setServiceTitle(items.fld_service_name);
    setShowForm(true);
  };

  const closeModal = () => {
    setShowForm(false);
  };
  return (
    <>
      <GetSeoData type="services" id={null} />

      <section className="inner-section service-page">
        {/* <NavBar /> */}
        <div className="page-title-area">
          <div className="container-fluid pe-md-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Our Services</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="spacing">
          <div className="container-fluid pt-4">
            <div className="row">
              <div className="col-12">
                <div className="main-heading text-center mb-0 col-12">
                  <h2>Explore Our Astrological Services</h2>
                  <h5>Nurture Your Spiritual Journey with Our Offerings</h5>
                  <p>
                    Our comprehensive range of astrological services are
                    designed to guide and empower you on your spiritual journey.
                    With a deep understanding of astrology, vastu shastra,
                    handwriting and signature analysis, tarot card reading,
                    palmistry, numerology, and reiki healing, we offer a
                    holistic approach to unravelling the mysteries of life and
                    tapping into your true potential.
                  </p>
                </div>
              </div>
              {serviceData?.length > 0 ? (
                serviceData?.map((items, index) => (
                  <div
                    className="col-md-6 col-lg-4 py-md-2 px-md-3 mb-3"
                    key={index}
                  >
                    <div className="service-card  position-relative  card-service">
                      <div>
                        <div className="icon-wrapper mb-3">
                          <img
                            src={items.fld_icon}
                            className="img-fluid"
                            alt={items.fld_service_name}
                          />
                        </div>
                        <h3>{items.fld_service_name}</h3>


                        <p
                          className="service-ellipse"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(items.fld_short_desc),
                          }}
                        />
                      </div>
                      <div className="d-flex gap-3 button-read mt-3">
                        <div className="read-more">
                          <Link
                            to={`/service/${items.fld_route}`}

                          >
                            Read More
                          </Link>
                        </div>
                        <div className="book-now">
                          <a onClick={() => showModal(items)}>Book Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="col-md-6 col-lg-4 py-md-2 px-md-3 mb-3">
                    <div className="service-card  position-relative  card-service">
                      <div>
                        <div className="icon-wrapper mb-3">
                          <Skeleton.Image className="img-fluid" />
                        </div>
                        <h3>
                          <Skeleton.Input />
                        </h3>
                        <p className="service-ellipse">
                          <Skeleton.Input />
                        </p>
                      </div>
                      <div className="d-flex gap-3 button-read">
                        <div className="read-more">
                          <Skeleton.Button />
                        </div>

                        <div className="book-now">
                          <a>
                            <Skeleton.Button />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 py-md-2 px-md-3 mb-3">
                    <div className="service-card  position-relative  card-service">
                      <div>
                        <div className="icon-wrapper mb-3">
                          <Skeleton.Image className="img-fluid" />
                        </div>
                        <h3>
                          <Skeleton.Input />
                        </h3>
                        <p className="service-ellipse">
                          <Skeleton.Input />
                        </p>
                      </div>
                      <div className="d-flex gap-3 button-read">
                        <div className="read-more">
                          <Skeleton.Button />
                        </div>

                        <div className="book-now">
                          <a>
                            <Skeleton.Button />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row">
              <div class="title-container text-center main-heading">
                <h2>Key Areas Explored in Astrology Readings</h2>
                <div className="bottom_content px-3 text-center">
                  <p>
                    Below is a detailed breakdown of our astrology session
                    offerings and their corresponding pricing in Indian Rupees
                    (INR). Feel free to select the session duration that aligns
                    with your preferences and needs.
                  </p>
                </div>
              </div>
              <div
                class="wrapper col-12 col-lg-6 text-center p-0"
                id="pricedown"
              >
                <div class="service-price-table me-md-1 mx-2">
                  <div class="table-responsive">
                    <table className="border">
                      <thead>
                        <tr>
                          <th colSpan="3" className="text-center">
                            Choose Your Astrological Focus
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="content-color">
                          <td>Personal Life</td>
                          <td>Family</td>
                          <td>Love Life</td>
                        </tr>
                        <tr className="content-color">
                          <td>Marriage</td>

                          <td>Happiness</td>
                          <td>Success</td>
                          {/* <td>Siblings</td> */}
                        </tr>
                        <tr className="content-color">
                          <td>Wealth</td>
                          <td>Career</td>
                          <td>Health</td>
                        </tr>
                        <tr className="content-color">
                          <td>Illness</td>

                          <td>Travel</td>
                          <td>Business</td>
                        </tr>
                        <tr>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="wrapper col-12 col-lg-6 text-center p-0 mt-lg-0 mt-3">
                <div class="service-price-table ms-md-1 mx-2">
                  <div class="table-responsive right-service-table">
                    <table className="border ">
                      <thead>
                        <tr>
                          <th colSpan="3" className="text-center">
                            {" "}
                            Choose Your Ideal Astrology Session Package
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr className="content-color">
                          <td>Understand Your Life Purpose and Personal Growth</td>
                          <td>

                            <div className="book-now">
                              <a onClick={() => showModal("")}>Book Now</a>
                            </div>
                          </td>
                        </tr>
                        <tr className="content-color">
                          <td>Get Insights on Relationships and Compatibility</td>

                          <td>
                            <div className="book-now">
                              <a onClick={() => showModal("")}>Book Now</a>
                            </div>
                          </td>
                        </tr>
                        <tr className="content-color">
                          <td>Discover Career Paths and Financial Growth</td>
                          <td>

                            <div className="book-now">
                              <a onClick={() => showModal("")}>Book Now</a>
                            </div>
                          </td>
                        </tr>
                        <tr className="content-color">
                          <td>Explore Astrological Influence on Health and Wellness</td>

                          <td>
                            <div className="book-now">
                              <a onClick={() => showModal("")}>Book Now</a>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </section>
      {showForm && (
        <Appointmentform
          serviceName={serviceTitle}
          showForm={showForm}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
