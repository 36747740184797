import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import testi from "../../img/testi-image.png";
// Import Swiper styles
import "swiper/css";

import "./testimonial.css";

// import required modules
import { Autoplay, Pagination } from "swiper";
import { FaStar } from "react-icons/fa";
import PostApiCall from "../../API/POSTAPI";

function Testimonial() {
  // const [testimonial, setTestimonial] = useState([])
  // useEffect(()=>{
  //     GetTestimonial();
  // },[])

  // const GetTestimonial = () => {
  //     PostApiCall.postRequest(
  //       {
  //         whereClause: "",
  //         recordCount: "*",
  //       },
  //       "GetTestimonial"
  //     ).then((results) => {
  //       results.json()?.then((obj) => {
  //         if (results.status === 200 || results.status === 201) {
  //           setTestimonial(obj.data);
  //           console.log(obj.data)
  //         }
  //       });
  //     });
  //   };
  return (
    <section className="spacing">
      <Container fluid className="mt-4">
        <Row>
          <Col xs={12} className="text-center t-head mb-0 main-heading">
            <h2 className="mb-0">What Do Our Clients Say About Us?</h2>
            <p>Here's What Our Clients Are Saying About Us</p>
          </Col>

          <Swiper
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper testimonial-swiper pt-3">
            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>Lovepreet Singh</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    I had the pleasure of consulting with Dr. Bhatnagar maam recently, and I must say, it was an incredibly insightful and enlightening experience. Their depth of knowledge and intuitive understanding of astrology truly impressed me. Not only did they provide accurate insights into my chart, but they also offered practical guidance and advice for navigating life's challenges. I left the session feeling empowered and more in tune with myself. I highly recommend Dr. Bhatnagar maam to anyone seeking clarity and guidance on their life path. Thank you for your wisdom and kindness!"🙏🏻😇

                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>Ashwani Bhat</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    Meeting Mam was very good experience, we both got lots of things known to us for first time in life,Quite amazing to explore astrological facts with Mam.
                    Hoping her guidance will bring required changes by following her guidelines.
                    I personally believe vedic guidance is always important and of course astrological planet movement hv grt impact on our lives.
                    Hence getting understanding of that will hv huge positive impact and direction to on going life from time to time.
                    Awesome Mam
                    Keep up good work.
                    Thanks
                    God bless

                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>VC</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    Surabhiji was very punctual and joined the what’s app call on time. I had a detailed one hour session with her, in which she covered all the aspects personal, professional, health, finance and kids. Extremely methodical, where she used 40-45 minutes in covering details from her side and rest of the time was dedicated to my questions.

                    Surabhiji  covered the strong as well as weak aspects of the kundali and provided clear and simple remedies to try for improvement of the weak aspects.

                    Accuracy in prediction was very high, with a very few outlier scenarios which did not aligning closely with me.

                    I have started trying some remedies and will diligently do the remaining ones. Also follow all her guidance too without any hesitation.

                    All my questions were covered with great details and patiently, in fact she went a few minutes overboard to clarify additional questions.

                    She also promised to have a feedback session with her to cover and adjust remedies.

                    What I loved about her the most is the personal relationship that was established when she said now you are a part of our blessings family and will be bonded with her for the rest of the life, and built a strong Trust.

                    This is my first astrologer and will be my only astrologer ever, without any doubt I would highly  recommend her and seek further guidance through out my life whenever needed !

                    Thank you Surabhi ji again highly appreciate it.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>Navita Anand</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    I never believed in astrology.But if u have a friend who is
                    an astrologer, u r always keen to ask for her guidance
                    whenever u feel the need .I consulted my friend Dr Surabhi
                    Bhatnagar and was truly bowled over by her analysis of my
                    chart . She is highly meticulous in her work , she helps u
                    understand about planets and its effects in your life. The
                    best part about Surabhi is her reading which is so honest
                    and truthful. She handles predictions very gracefully.I
                    highly recommend her as she has been into spirituality for
                    about 15 years now. Surabhi is a must go to person, when u
                    need a perfect guidance and direction in life. I m sure u
                    will never be disappointed and come feeling sorted and
                    balanced in life .
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>Seema Gupta</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    Hello Surabhi ji mujhe aapne meri life se related jo bhi
                    baate batain wo bahut accurate lagi my experience with you
                    is good but Mera upay abhi nhi ho paya Surabhi Bhatnagar
                    Blessings: M waise bhi aapse soch rhi thi baat karne k liye
                    Time lungi Surabhi Bhatnagar Blessings: One thing I also
                    want to share about you i feel very positive and comfortable
                    with you once again thankyou for guiding .
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>Kiran Chaudhary</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    I acknowledge that i am little late in posting this but
                    would like to share feedback for my experience with DR.
                    Surabhi on astrology prediction and remedies. I approached
                    Srabhi for self and for my sister as well and her
                    predictions are so accurate and remedies are very very
                    simple that any one can follow it along with daily
                    responsibilities. Most importantly very very effective.
                    Would like to Thank her for guiding us. Personally also she
                    is such a positive person that u feel so comfortable with
                    her.
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>Pallavi</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    {" "}
                    Dr. Surabhi is very positive and encouraging person. Her simple
                    methods for problem solving are very good. Her predictions
                    were correct. Surabhi Bhatnagar Blessings: After using those
                    methods I feel very relaxed and peaceful. May God shower
                    blessings on you. Thank you.
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>Deep Srivastav</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    I had a session with Surabhiji regarding the personal loss
                    which I was going through. It was satisfying session and I
                    could easily share my emotions with Surabhiji. Thanks to
                    her.
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>Rakshica Srivastava</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    Surabhi is the most knowledgeable astrologer I have ever
                    met. She is very considerate and understands every situation
                    in depth then tries to give the best solution . Her Reiki
                    sessions are really helpful and we have seen significant
                    improvement in mother's health. She is not just an
                    astrologer but also an angel for us because whenever we are
                    in a tough situation she is a great healer. I really
                    recommend her for all her astrological services 🙏.
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3>Pooja</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    Surabhi ji hi I just wanted to thank you for being the best
                    guru and a constant support. Thanks for teaching each topic
                    patiently and vividly. I will now and forever cherish you as
                    my “Guru”. Please do visit us when you come to Australia. We
                    will be very happy to host. I am sure our teaching journey
                    continues further with each passing year.I will also take
                    guidance regularly and vastu details for our new home before
                    we set it up. I wish you the best and may you succeed in all
                    your future endeavours. Take care!!
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonial-card">
                {/* <div className="d-flex justify-content-between"> */}

                {/* <div className="testimonial-content"> */}
                <h3> Alok Ratan Bhatnagar</h3>

                {/* </div> */}
                <div className="testimonial-image">
                  <img src={testi} className="img-fluid"></img>
                </div>
                {/* </div> */}
                <div className="star mb-2">
                  <FaStar />
                  <FaStar />

                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="testimonial-content">
                  <p>
                    Happy New Year & Prosperous 2023 Dear Surabhi & Your family
                    members, stay blessed. I forgot to update you about My
                    Cataract Operation which was successfully with your
                    blessings and guidance done on 22-Dec-2022. Free Lens Given
                    to me by Hospital not much expense done.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Row>
      </Container>
    </section>
  );
}

export default Testimonial;
