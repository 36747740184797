import React, { useState } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../API/POSTAPI";
import { FaLockOpen } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
export default function ChangePassword({ isOpen, handdleToggleModal }) {
	const [newPassword, SetNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const history = useHistory();

	const onChangePassword = () => {
		Notiflix.Loading.dots('');
		var login = localStorage.getItem('CustomerData');
		var details = JSON.parse(login)
		PostApiCall.postRequest({
			userid: details[0].fld_customerid,
			password: confirmPassword,
			salt: details[0].fld_salt,
		}, "ChangeCustomerPassword").then((results) =>
			results.json().then(obj => {
				if (results.status == 200 || results.status == 201) {
					Notiflix.Loading.remove()
					Notiflix.Notify.success('Password Successfully updated')
					handdleToggleModal();
					history.push('/')
				}
				else {
					Notiflix.Loading.remove()
					Notiflix.Notify.failure('something went wrong, try again later')
				}
			}
			)
		)
	}

	const handleChangePassword = () => {
		if (newPassword === "") {
			Notiflix.Notify.failure('New Password can not be empty')
			return;
		} else if (confirmPassword === "") {
			Notiflix.Notify.failure('Confirm Password can not be empty')
			return;
		} else if (newPassword !== confirmPassword) {
			Notiflix.Notify.failure("New Password and Confirm New Password does not match")
			return;
		} onChangePassword();
	}


	return (
		<>


			<Modal show={isOpen} onHide={handdleToggleModal} centered>
				<Modal.Header closeButton className="pb-0 border-0">
				</Modal.Header>
				<Modal.Body>
					<div className="change-pass-icon mb-4">
						<FaLockOpen />
						<h2>Change Password</h2>
					</div>
					<Form>
						<Form.Group controlId="newPassword" className="mb-3">
							<Form.Control
								type="password"
								placeholder="New Password"
								value={newPassword}
								onChange={(e) => SetNewPassword(e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId="confirmPassword">
							<Form.Control
								type="password"
								placeholder="Confirm Password"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
						</Form.Group>
					</Form>
					<div className="mt-3">
						<button
							className=" text-center w-100 d-block border-0 address"
							onClick={handleChangePassword}>
							Change Password
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
