import React, { Component, useContext, useEffect, useState } from "react";
import HomeBooks from "../home/HomeBooks/HomeBooks";
import Homecategorypage from "../homecategory/category";
import { useParams } from "react-router-dom";
import PostApiCall from "../API/POSTAPI";
import GetApiCall from "../API/GETAPI";
import { Skeleton } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Autoplay, Pagination } from "swiper";
import corporate from "../img/corporate-training.png";


import "./ShopOnline.css";
import { Col } from "react-bootstrap";
import Appointmentform from "../appointmentpage/appointmentform";
export default function ShopOnline() {

    const [horoscopeData, setHoroscopeData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [booksData, setBooksData] = useState([]);
    const [serviceTitle, setServiceTitle] = useState("")

    useEffect(() => {
        GetServiceData();
        PostApiCall.postRequest(
            {
                categoryid: 5,
                stateid: null,
            },
            "Get_All_Items"
        ).then((results) =>
            results.json().then((obj1) => {
                if (results.status === 200 || results.status === 201) {
                    const sortbyLatest = obj1.data.sort(
                        (a, b) => b.fld_variantid - a.fld_variantid
                    );
                    setBooksData(sortbyLatest);
                }
            })
        );
    }, []);

    const showModal = (items) => {
        setServiceTitle(items.title)
        setShowForm(true);
    };
    const GetServiceData = () => {
        PostApiCall.postRequest(
            {
                catCount: "*",
                whereClause: "",
            },
            "Services"
        ).then((results) =>
            results.json().then((obj1) => {
                if (results.status === 200 || results.status === 201) {
                    setServiceData(obj1.data.filter((data) => data.fld_status === "Active"));
                }
            })
        );
    };


    const closeModal = () => {
        setShowForm(false);
    };

    const [homeProducts, setHomeProducts] = useState([]);
    const GetHomeProducts = () => {
        GetApiCall.getRequest("GetHomepageSetup")
            .then((result) => result.json())
            .then((res) => {
                // console.log(res.data)
                res.data
                    .filter((value) => value.fld_section_name === "Featured Products")
                    .forEach((filtereddata) => {
                        PostApiCall.postRequest(
                            {
                                sectionname: filtereddata.fld_section_name,
                                itemchoice: filtereddata.fld_item_choice,
                            },
                            "GetWebsiteHomePageItems"
                        ).then((results) => {
                            if (results.status === 200 || results.status === 201) {
                                results.json().then((data) => {
                                    setHomeProducts(data.data);
                                });
                            }
                        });
                    });
            });
    }

    useEffect(() => {
        GetApiCall.getRequest("GetBannersV1").then((results) =>
            results.json().then((obj1) => {
                if (results.status === 200 || results.status === 201) {

                    var filterBanner = obj1.data.filter(
                        (item) => item.location === "Main"
                    );
                    var Parallax1Banner = obj1.data.filter(
                        (item) => item.location === "Parallax"
                    );
                    var Parallax2Banner = obj1.data.filter(
                        (item) => item.location === "Parallax2"
                    );
                    // var RightMainBanner = obj1.data.filter(
                    //   (item) => item.location === "MainRight"
                    // );
                    // var CatBanner = obj1.data.filter(
                    //   (item) => item.location === "CategoryBanner"
                    // );
                    // setMainBanner(filterBanner);
                    // setParallax2Banner(Parallax1Banner);
                    // setParallax2Banner(Parallax2Banner);
                    // setMainRightBanner(RightMainBanner)
                    // setCategoryBanner(CatBanner)
                }
            })
        );
        GetHomeProducts();
    }, []);
    return (
        <>
            <div className="page-title-area">
                <div className="container-fluid pe-md-4 h-100 d-flex justify-content-end align-items-center">
                    <div className="page-title-content">
                        <ul>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Our Services</li>
                        </ul>
                    </div>

                </div>
            </div>
            <Homecategorypage />
            <HomeBooks booksData={booksData} />




            <section className="spacing ">
                <div className="container-fluid">
                    <div className="row">
                        <Col xs={12} className="main-heading mb-0 mt-3 text-center">
                            {/* <h4><span>What Do Our Clients Say</span> About Us?</h4> */}
                            <h2>Our Services</h2>
                            <p>Nurture Your Spiritual Journey with Our Offerings</p>
                        </Col>

                        <div className="col-12">
                            <Swiper
                                spaceBetween={30}
                                loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }}
                                autoplay={{
                                    delay: 5000,
                                    pauseOnMouseEnter: true,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper testimonial-swiper services-swiper pt-2"
                            >
                                {serviceData?.length > 0 ? (
                                    serviceData?.map((items, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="">
                                                <div className="service-card">
                                                    <div className="icon-wrapper">
                                                        <img src={items.fld_icon} className="img-fluid" alt={items.fld_service_name}></img>
                                                    </div>
                                                    <h3>{items.fld_service_name}</h3>
                                                    <p>
                                                        {items?.fld_short_desc.replace(/<\/?p>/g, '').replace(/<\/?h2>/g, '')}
                                                        {/* Astrology, also known as Jyotish, is an ancient
                                                        and divine knowledge that can see into our past... */}
                                                    </p>
                                                    <div className="d-flex gap-3 mt-3">
                                                        <div className="read-more">
                                                            <Link
                                                                to={`/service/${items?.fld_route}`}
                                                            >
                                                                {" "}
                                                                Read More
                                                            </Link>
                                                        </div>
                                                        <div className="book-now" >
                                                            <a onClick={() => showModal(items)}> Book Now</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                ) :
                                    <>
                                        <SwiperSlide>
                                            <div className="">
                                                <div className="service-card">
                                                    <div className="icon-wrapper">
                                                        <Skeleton.Image></Skeleton.Image>
                                                    </div>
                                                    <h3>
                                                        <Skeleton.Input />
                                                    </h3>
                                                    <p>
                                                        <Skeleton.Input />
                                                    </p>
                                                    <div className="d-flex gap-3">
                                                        <div className="read-more">
                                                            <Skeleton.Button />
                                                        </div>
                                                        <div className="book-now">
                                                            <a href="/contact-us">
                                                                <Skeleton.Button />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        {/* <SwiperSlide>
                                            <div className="">
                                                <div className="service-card">
                                                    <div className="icon-wrapper">
                                                        <Skeleton.Image></Skeleton.Image>
                                                    </div>
                                                    <h3>
                                                        <Skeleton.Input />
                                                    </h3>
                                                    <p>
                                                        <Skeleton.Input />
                                                    </p>
                                                    <div className="d-flex gap-3">
                                                        <div className="read-more">
                                                            <Skeleton.Button />
                                                        </div>
                                                        <div className="book-now">
                                                            <a href="/contact-us">
                                                                <Skeleton.Button />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="">
                                                <div className="service-card">
                                                    <div className="icon-wrapper">
                                                        <Skeleton.Image></Skeleton.Image>
                                                    </div>
                                                    <h3>
                                                        <Skeleton.Input />
                                                    </h3>
                                                    <p>
                                                        <Skeleton.Input />
                                                    </p>
                                                    <div className="d-flex gap-3">
                                                        <div className="read-more">
                                                            <Skeleton.Button />
                                                        </div>
                                                        <div className="book-now">
                                                            <a href="/contact-us">
                                                                <Skeleton.Button />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="">
                                                <div className="service-card">
                                                    <div className="icon-wrapper">
                                                        <Skeleton.Image></Skeleton.Image>
                                                    </div>
                                                    <h3>
                                                        <Skeleton.Input />
                                                    </h3>
                                                    <p>
                                                        <Skeleton.Input />
                                                    </p>
                                                    <div className="d-flex gap-3">
                                                        <div className="read-more">
                                                            <Skeleton.Button />
                                                        </div>
                                                        <div className="book-now">
                                                            <a href="/contact-us">
                                                                <Skeleton.Button />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide> */}
                                    </>
                                }
                            </Swiper>
                        </div>

                    </div>

                </div>
            </section >
            {showForm && <Appointmentform serviceName={serviceTitle} showForm={showForm} closeModal={closeModal} />}

        </>
    )
}

