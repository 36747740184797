import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import Ariesblog from "../../img/blog/blogaries.png";
import Taurasblog from "../../img/blog/taurusblog.png";
import Geminiblog from "../../img/blog/bloggemini.png";
// import Geminiblog from "../../img/blog/bloggemini.png";
// import Cancerblog from "../../img/blog/blogleo.png";
import bloggemini from "../../img/blog/bloggemini.png";
import Cancerblog from "../../img/blog/blogcancer.png";
// import bloggemini from "../img/blog/bloggemini.png";
import blogleo from "../../img/blog/blogleo.png";
import blogvirgo from "../../img/blog/blogvirgo.png";
// Import Swiper styles
import "swiper/css";

import "./HomeBlogs.css";

// import required modules
import { Autoplay, Pagination } from "swiper";
import { FaCalendarAlt } from "react-icons/fa";
import GetApiCall from "../../API/GETAPI";
import { Skeleton } from "antd";

function HomeBlogs() {
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    GetBlogArticle();
  }, []);
  const GetBlogArticle = () => {
    GetApiCall.getRequest("GetBlogArticle").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        setBlogData(obj.data);
      });
    });
  };
  return (
    <section className="spacing">
      <Container fluid className="mt-4">
        <Row>
          <Col xs={12} className="text-center t-head mb-0 main-heading">
            <h2 className="mb-0">Our Blogs</h2>
            <p>Read Our Blogs for Deeper Astrological Understanding</p>
          </Col>

          <Swiper
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper testimonial-swiper blog-skeleton pt-lg-3">
            {blogData.length > 0 ? (
              <>
                {blogData.map((data, index) => (
                  <SwiperSlide key={index}>
                    <div className="" >
                      <div className="icon-wrapper mb-lg-3 home-blog">
                        <Link
                          to={`/blog/${data.fld_route}`}>
                          {" "}
                          <img
                            src={data.fld_blogimage}
                            className=" img-fluid aries-image"
                            alt="Astrology Services"></img>
                        </Link>
                      </div>
                      {/* <p><FaCalendarAlt className="" />
                                    02-10-24</p> */}
                      <h3 className="mt-3">
                        <Link
                          to={`/blog/${data.fld_route}`}>
                          {data.fld_title}
                        </Link>
                      </h3>
                      {/* <p>{parse(data.fld_short_description.slice(0, 100))}...</p> */}
                      <p className="blog-short-desc">
                        {parse(data.fld_short_description)}
                      </p>

                      {/* <div className="d-flex align-items-start justify-content-start ">
                                    <a href="/aries" className="button button-2 ">Read More</a>
                                </div> */}
                      <div className="read-more">
                        <Link
                          className="d-inline-block"
                          to={`/blog/${data.fld_route}`}>
                          {" "}
                          Read More
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                <SwiperSlide>
                  <div className="">
                    <div className="icon-wrapper mb-lg-3">
                      <a href="/aries">
                        {" "}
                        <Skeleton.Image
                          className=" img-fluid aries-image w-100 h-auto"
                          alt="Astrology Services"
                        />
                      </a>
                    </div>
                    <h3>
                      <a href="/aries">
                        <Skeleton.Input />
                      </a>
                    </h3>
                    <p className="blog-para-content ">
                      <Skeleton.Input />
                    </p>
                    <Skeleton.Button />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="icon-wrapper mb-lg-3">
                      <a href="/aries">
                        {" "}
                        <Skeleton.Image
                          className=" img-fluid aries-image w-100 h-auto"
                          alt="Astrology Services"
                        />
                      </a>
                    </div>
                    <h3>
                      <a href="/aries">
                        <Skeleton.Input />
                      </a>
                    </h3>
                    <p>
                      <Skeleton.Input />
                    </p>
                    <Skeleton.Button />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="icon-wrapper mb-lg-3">
                      <a href="/aries">
                        {" "}
                        <Skeleton.Image
                          className=" img-fluid aries-image w-100 h-auto"
                          alt="Astrology Services"
                        />
                      </a>
                    </div>
                    <h3>
                      <a href="/aries">
                        <Skeleton.Input />
                      </a>
                    </h3>
                    <p className="blog-para-content ">
                      <Skeleton.Input />
                    </p>
                    <Skeleton.Button />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="icon-wrapper mb-lg-3">
                      <a href="/aries">
                        {" "}
                        <Skeleton.Image
                          className=" img-fluid aries-image w-100 h-auto"
                          alt="Astrology Services"
                        />
                      </a>
                    </div>
                    <h3>
                      <a href="/aries">
                        <Skeleton.Input />
                      </a>
                    </h3>
                    <p className="blog-para-content ">
                      <Skeleton.Input />
                    </p>
                    <Skeleton.Button />
                  </div>
                </SwiperSlide>
              </>
            )}
          </Swiper>
        </Row>
      </Container>
    </section>
  );
}

export default HomeBlogs;
