import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Autoplay } from "swiper";
import "./services.css";

import { Skeleton } from "antd";
import createDOMPurify from "dompurify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Appointmentform from "../../appointmentpage/appointmentform";
const DOMPurify = createDOMPurify(window);
function Services({ serviceData }) {
  const [serviceTitle, setServiceTitle] = useState("");
  const [showForm, setShowForm] = useState(false);
  const showModal = (items) => {
    setShowForm(true);
    setServiceTitle(items.fld_service_name);
  };

  function refreshPage() {
    window.location.reload();
  }
  const closeModal = () => {
    setShowForm(false);
  };
  return (
    <>
      <section className="spacing services-section mobile-spacing mt-4 mt-lg-3">
        <div className="container-fluid  py-4">
          <div className="row">
            <div className="text-center text-white col-12 text-center t-head mb-2 main-heading">
              <h2 className="mb-0">Our Services</h2>
              <p>Guiding You with Expert Astrology for Life's Path</p>
            </div>

            <Swiper
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              autoplay={{
                delay: 5000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper testimonial-swiper services-swiper pt-2"
            >
              {serviceData?.length > 0 ? (
                serviceData?.map((items, index) => (
                  <SwiperSlide key={index}>
                    <div className="product-card servicee-card">
                      <img
                        src={items.fld_image}
                        className="img-fluid"
                        alt="Blessing Products"
                      />

                      <div className="service-content text-center">
                        <div>
                          <h4>{items.fld_service_name}</h4>
                          <div className="para-service">
                            <p
                              className="home-service-font"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  items?.fld_short_desc
                                ),
                              }}
                            >
                              {/* {items?.fld_short_desc.replace(/<\/?p>/g, "")} */}
                              {/* Astrology, also known as Jyotish, is an ancient
                              and divine knowledge{" "} */}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mb-2">
                          <div className="read-more m-2">
                            <Link
                              to={`/service/${items?.fld_route}`}
                            // onClick={refreshPage}
                            >
                              {" "}
                              Read More
                            </Link>
                          </div>
                          <div className="book-now m-2">
                            <a onClick={() => showModal(items)}>Book Now</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <>
                  <SwiperSlide>
                    <div className="product-card servicee-card">
                      <Skeleton.Image
                        className="img-fluid w-100 h-auto"
                        alt="Blessing Products"
                      />

                      <div className="product-content text-center">
                        <h4>
                          <Skeleton.Input />
                        </h4>

                        <div className="para-service">
                          <p>
                            <Skeleton.Input />{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-card servicee-card">
                      <Skeleton.Image
                        className="img-fluid w-100 h-auto"
                        alt="Blessing Products"
                      />

                      <div className="product-content text-center">
                        <h4>
                          <Skeleton.Input />
                        </h4>

                        <div className="para-service">
                          <p>
                            <Skeleton.Input />{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-card servicee-card">
                      <Skeleton.Image
                        className="img-fluid w-100 h-auto"
                        alt="Blessing Products"
                      />

                      <div className="product-content text-center">
                        <h4>
                          <Skeleton.Input />
                        </h4>

                        <div className="para-service">
                          <p>
                            <Skeleton.Input />{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-card servicee-card">
                      <Skeleton.Image
                        className="img-fluid w-100 h-auto"
                        alt="Blessing Products"
                      />

                      <div className="product-content text-center">
                        <h4>
                          <Skeleton.Input />
                        </h4>

                        <div className="para-service">
                          <p>
                            <Skeleton.Input />{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              )}
            </Swiper>
          </div>
        </div>
      </section>
      {showForm && (
        <Appointmentform
          serviceName={serviceTitle}
          showForm={showForm}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
export default Services;
